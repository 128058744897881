import { Casts, Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { Customer } from '../Customer';
import { CustomerRouteActivityTemplateStore } from './CustomerRouteActivityTemplate';
import { CustomerRouteSurchargeStore } from './CustomerRouteSurcharge';
import { SURCHARGE_TYPE_PERCENTAGE } from '../enums/SurchargeTypes';

export class CustomerRoute extends Model {
    static backendResourceName = 'customer_route';

    @observable id = null;
    @observable name = null;
    @observable startDate = null;
    @observable endDate = null;
    @observable remarks = null;

    /**
     * Calculates the amount for a specific surcharge in the customer route. If it is a percentage it will actually
     * calculate the value after taking the percentage of the related surcharge.
     * @param code - can be string or Surcharge
     * @returns {number|*}
     */
    getAmountForSurcharge(code) {
        if (typeof code !== 'string') {
            code = code.code;
        }
        let customerRouteSurcharge = this.customerRouteSurcharges.find(crs => crs.surcharge.code === code)
        if (!customerRouteSurcharge){
            return 0;
        }

        // Always use an adaptedAmount if it exists
        if (customerRouteSurcharge.adaptedAmount) {
            return customerRouteSurcharge.adaptedAmount;
        }

        // Use the amount if possible otherwise just calculate it
        if (customerRouteSurcharge.surcharge?.type !== SURCHARGE_TYPE_PERCENTAGE) {
            return customerRouteSurcharge.amount;
        } else {
            let percentage = customerRouteSurcharge.surcharge.percentage / 100;
            let related_surcharge_amount = this.getAmountForSurcharge(customerRouteSurcharge.surcharge.relatedSurcharge.code)
            return related_surcharge_amount * percentage;
        }
    }

    /**
     * The surchargeSum is the sum of all the surcharges, if there is an adapted amount it should be used instead of the
     * original surcharge amount
     *
     * If there is a surcharge that is a percentage, we have to lookup the current value of that surcharge and our new
     * value will be the percentage of the value of that surcharge
     *
     * @returns {number|null}
     */
    get calculatedSurchargeSum(){
        if (!this.customerRouteSurcharges){
            return null;
        }

        let sum = 0
        this.customerRouteSurcharges.forEach(crs => {
            // Not included if disabled
            if (!crs.enabled){
                return sum += 0
            }

            return sum += this.getAmountForSurcharge(crs.surcharge)
        })

        return sum
    }

    /**
     * The suggestedSum is the sum of all the surcharges, ignoring the adapted surcharge amount
     * @returns {number|null}
     */
    get suggestedSum(){

        if (!this.customerRouteSurcharges){
            return null;
        }

        let sum = 0
        this.customerRouteSurcharges.forEach(crs => {sum += crs.enabled ? crs.surcharge._amount : 0})

        return sum
    }

    generateCopy(customer = null) {
        const customerRouteCopy = new CustomerRoute(this.toJS(), { relations: this.__activeRelations })
        customerRouteCopy.setInput('id', null)
        customerRouteCopy.customerRouteSurcharges.forEach(crs => {
            crs.setInput('id', crs.getNegativeId())
        })
        customerRouteCopy.activityTemplates.forEach(template => {
            template.setInput('id', template.getNegativeId())
        })

        if (customer) {
            customerRouteCopy.__activeRelations.push('customer')
            customerRouteCopy.__activeCurrentRelations.push('customer')
            customerRouteCopy.customer = customer
            customerRouteCopy.customer.setInput('id', customer.id)
        }

        return customerRouteCopy
    }

    relations() {
        return {
            customer: Customer,
            customerRouteSurcharges: CustomerRouteSurchargeStore,
            activityTemplates: CustomerRouteActivityTemplateStore,
        };
    }

    casts() {
        return {
            startDate: Casts.date,
            endDate: Casts.date,
        };
    }
}

export class CustomerRouteStore extends Store {
    Model = CustomerRoute;
    static backendResourceName = 'customer_route';
}
