// Hacky helper to ease working with notifications.
let viewStore = null;

export function configureCurrentUser(givenViewStore) {
    viewStore = givenViewStore;
}

export function getCurrentUser() {
    return viewStore.currentUser;
}

export function hasPermission(...args) {
    if (viewStore.currentUser.hasPermission) {
        return viewStore.currentUser.hasPermission(...args);
    }

    // Fallback for projects who don't have hasPermission.
    return true;
}

export function inGroup(...args) {
    if (viewStore.currentUser.inGroup) {
        return viewStore.currentUser.inGroup(...args);
    }

    // Fallback for projects who don't have hasPermission.
    return false;
}

export function isCustomerPortalUser() {
    return viewStore.currentUser.hasCustomerPortal;
}

export function isDriverUser() {
    return viewStore.currentUser.isDriver;
}
