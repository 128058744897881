import { Casts, Model, Store } from 'store/Base';
import { computed, observable } from 'mobx';
import { ScheduleStore } from './Schedule';
import { Terminal } from './Terminal';
import { Transporter } from './Transporter';
import RouteStatus from './enums/RouteStatus';
import { DateTime } from 'luxon';


export class Route extends Model {
    static backendResourceName = 'route';

    @observable id = null;
    @observable status = RouteStatus.DRAFT;
    @observable name = '';
    @observable code = '';
    @observable invoiceWay = '';
    @observable westBound = null;

    @observable createdAt = null;
    @observable updatedAt = null;

    @observable validFrom = DateTime.now().plus({ day: 1 }).startOf('day');
    @observable validTo = null;

    @computed
    get canBeDeactivated() {
        return this.status === RouteStatus.CURRENT && !this.validTo;
    }

    @computed
    get canBeEdited() {
        return this.status === RouteStatus.FUTURE || this.status === RouteStatus.DRAFT;
    }

    @computed
    get canBeDeleted() {
        return this.status === RouteStatus.FUTURE || this.status === RouteStatus.DRAFT;
    }



    relations() {
        return {
            fromTerminal: Terminal,
            toTerminal: Terminal,
            transporter: Transporter,
            schedules: ScheduleStore,
            previousRoute: Route
        };
    }

    casts() {
        return {
            validFrom:  Casts.datetime,
            validTo: Casts.datetime,
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime
        };
    }
}

export class RouteStore extends Store {
    Model = Route;
    static backendResourceName = 'route';
}
