import { Casts, Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { SurchargeAmountStore } from './SurchargeAmount';
import { SURCHARGE_TYPE_FIXED, SURCHARGE_TYPE_PERCENTAGE } from '../enums/SurchargeTypes';

export const SURCHARGE_CODE_BASE_PRICE = '01'
export const SURCHARGE_CODE_FUEL = '02'
export const SURCHARGE_CODE_ADR = '03'

export class Surcharge extends Model {
    static backendResourceName = 'surcharge';
    static omitFields = ['amount', 'percentage', 'validFrom', 'validTo'];


    @observable id = null;
    @observable code = '';
    @observable name = '';
    @observable amount
    @observable percentage
    @observable validFrom
    @observable validTo

    @observable type = SURCHARGE_TYPE_FIXED;

    get _amount() {
        let amount;
        if (this.type !== SURCHARGE_TYPE_PERCENTAGE){
            amount = this.amount;
        } else {
            amount = this.percentage / 100 * this.relatedSurcharge?.amount
        }
        return amount || 0
    }
    relations() {
        return {
            relatedSurcharge: Surcharge,
            amounts: SurchargeAmountStore,
        }
    }

    casts() {
        return {
            validFrom: Casts.date,
            validTo: Casts.date,
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime
        };
    }
}

export class SurchargeStore extends Store {
    Model = Surcharge;
    static backendResourceName = 'surcharge';
}
