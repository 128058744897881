export const INVOICE_ONLY = 'invoice_only'
export const POD_ONLY = 'pod_only'
export const INVOICE_POD_SENT_SEPARATELY = 'invoice_pod_sent_separately'
export const INVOICE_POD_ONE_FILE = 'invoice_pod_merged_in_one_file'


export const PERSONALIZE_ATTACHMENT = [
    INVOICE_ONLY,
    POD_ONLY,
    INVOICE_POD_SENT_SEPARATELY,
    INVOICE_POD_ONE_FILE
];

export default { INVOICE_ONLY, POD_ONLY,INVOICE_POD_SENT_SEPARATELY, INVOICE_POD_ONE_FILE }
