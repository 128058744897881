import { Model, Store } from 'store/Base';
import { computed, observable } from 'mobx';
import { TruckingCompany } from 'store/TruckingCompany';
import { PurchaseInvoiceSubcontractOrderLineStore } from 'store/PurchaseInvoiceSubcontractOrderLine';

export class PurchaseInvoiceSubcontract extends Model {
    static backendResourceName = 'purchase_invoice_subcontract';

    @observable id = null;

    @computed
    get invoiceAmountComputed() {
        return this.orderLines?.map(line => line.amount).reduce((a, b) => a + b, 0);
    }

    relations() {
        return {
            truckingCompany: TruckingCompany,
            orderLines: PurchaseInvoiceSubcontractOrderLineStore,
        };
    }
}

export class PurchaseInvoiceStore extends Store {
    Model = PurchaseInvoiceSubcontract;
    static backendResourceName = 'purchase_invoice_subcontract';
}
