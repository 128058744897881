import { Casts, Model, Store } from 'store/Base';
import { observable, computed, action } from 'mobx';
import { TruckingCompany } from 'store/TruckingCompany';
import { PurchaseInvoiceSelfBillTripActivityLineStore } from 'store/PurchaseInvoiceSelfBillTripActivityLine';
import { PurchaseInvoice, STATUS_DRAFT, STATUS_PENDING } from './PurchaseInvoice';
import { DAILY_RATE, OTHER_COSTS } from './enums/CorrectionType';
import { DateTime, Interval } from 'luxon';

export class PurchaseInvoiceSelfBill extends Model {
    static backendResourceName = 'purchase_invoice_self_bill';

    @observable id = null;
    @observable selfBillDate = null;

    casts() {
        return {
            selfBillDate: Casts.date,
        };
    }

    @computed
    get invoiceAmountComputed() {
        return this.tripActivityLines?.map(line => line.amount).reduce((a, b) => a + b, 0);
    }

    @computed
    get selfBillError() {
        if (this.tripActivityLines?.models?.length > 0 && this.selfBillDate == null) {
            return [t('purchaseInvoiceSelfBill.field.selfBillDate.errorMultipleWeeks')]
        }

        return [];
    }

    @computed get correctionTotal() {
        return this.tripActivityLines?.map(line => line.corrections).reduce((a, b) => a + b?.models.reduce((a, b) => a + b.amount, 0), 0);
    }

    @computed get correctionTotalDailyRate() {
        return this.tripActivityLines?.map(line => line.corrections).reduce((a, b) => a + b?.models.filter(line => line.type === DAILY_RATE).reduce((a, b) => a + b.amount, 0), 0);
    }

    @computed get correctionTotalOther() {
        return this.tripActivityLines?.map(line => line.corrections).reduce((a, b) => a + b?.models.filter(line => line.type === OTHER_COSTS).reduce((a, b) => a + b.amount, 0), 0);
    }

    getSelfBillDate = () => {
        // Extract valid dates (ignoring null finalizeAt dates)
        const dates = this.tripActivityLines
            .map(line => line.tripActivity.activity.statusFinishedAt)
            .filter(statusFinishedAt => statusFinishedAt !== null)
            .map(statusFinishedAt => DateTime.fromISO(statusFinishedAt));

        // If there are no valid dates, return null
        if (dates.length === 0) {
            return null;
        }

        // Function to get the start of the week (Monday)
        const getMonday = (date) => date.startOf('week');

        const monday = getMonday(dates[0]);
        const friday = monday.plus({ days: 4 }); // Friday is 4 days after Monday

        // Check if all dates are within the same week
        const sameWeek = dates.every(date => {
            const interval = Interval.fromDateTimes(monday, monday.plus({ days: 7 }));
            return interval.contains(date);
        });

        return sameWeek ? friday : null;
    };



    getSuggestedSelfBillDate() {
        const weekFriday = this.getSelfBillDate();
        if (weekFriday) {
            return weekFriday;
        } else {
            return null;
        }
    }

    setSuggestedSelfBillDateInput = action((status) => {
        if (status === STATUS_DRAFT || status === STATUS_PENDING) {
            const selfBillDate = this.getSuggestedSelfBillDate();
            this.setInput('selfBillDate', selfBillDate);
            this.selfBillDate = selfBillDate;
        }
    })


    relations() {
        return {
            purchaseInvoice: PurchaseInvoice,
            truckingCompany: TruckingCompany,
            tripActivityLines: PurchaseInvoiceSelfBillTripActivityLineStore,
        };
    }
}

export class PurchaseInvoiceStore extends Store {
    Model = PurchaseInvoiceSelfBill;
    static backendResourceName = 'purchase_invoice_self_bill';
}
