export const LOAD = 'load';
export const UNLOAD = 'unload';
export const TERMINAL_DROP = 'terminal_drop';
export const TERMINAL_PICK_UP = 'terminal_pick_up';
export const YARD_DROP = 'yard_drop';
export const YARD_PICK_UP = 'yard_pick_up';
export const DALESSI_ACTIVITY = 'dalessi_activity';
export const CUSTOMER_ACTIVITY = 'customer_activity';
export const TRUCK_PICK_UP = 'truck_pick_up';
export const TRUCK_SERVICE = 'truck_service';
export const TRAILER_SERVICE = 'trailer_service';
export const ACTIVITY_TYPES = [LOAD, UNLOAD, TERMINAL_DROP, TERMINAL_PICK_UP, YARD_DROP, YARD_PICK_UP, DALESSI_ACTIVITY, CUSTOMER_ACTIVITY, TRUCK_PICK_UP, TRUCK_SERVICE, TRAILER_SERVICE];
export default { LOAD, UNLOAD, TERMINAL_DROP, TERMINAL_PICK_UP, YARD_DROP, YARD_PICK_UP, DALESSI_ACTIVITY, CUSTOMER_ACTIVITY, TRUCK_PICK_UP, TRUCK_SERVICE, TRAILER_SERVICE };
