import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Container, Table } from 'semantic-ui-react';
import { ModuleRepository } from '../../module/repository';
import { ModuleDescription } from '../components/moduleDescription';
import { ModuleInterface } from '../../module/module';
import { t } from 'i18n';

interface ModuleScreenProps {
    moduleRepository: ModuleRepository;
}

@observer
export class ModuleScreen extends Component<ModuleScreenProps> {

    renderRow = (module: ModuleInterface) => {
        return (
            <Table.Row>
                <Table.Cell>{module.name}</Table.Cell>
                <Table.Cell>{Object.keys(module.translations).join(', ')}</Table.Cell>
                <Table.Cell>{module.routes.map(route => route.path).join('<br />')}</Table.Cell>
            </Table.Row>
        )
    }

    render() {
        const { moduleRepository } = this.props;
        return (
            <Container>
                <ModuleDescription/>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                {t('system.module.field.name.label')}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t('system.module.field.translations.label')}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t('system.module.field.routes.label')}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {moduleRepository.modules.map(this.renderRow)}
                    </Table.Body>
                </Table>
            </Container>
        )
    }

}
