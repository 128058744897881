import { Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { Driver } from './Driver';

export class DriverDataSource extends Model {
    static backendResourceName = 'driver_data_source';

    @observable id: number | null = null;
    @observable dataSource = '';
    @observable dataExternalId = '';
    @observable activeTachoEvent = false;
    @observable activeMessage = false;

    @observable driver = this.relation(Driver);
}

export class DriverDataSourceStore extends Store {
    Model = DriverDataSource;
    static backendResourceName = 'driver_data_source';
}
