import { Model, Store, Casts, OrderedStore } from 'store/Base';
import { computed, observable } from 'mobx';
import { Location } from './Location';
import { Order } from './Order';
import { TaskStore } from './Task';
import { ActivityStore } from './Activity';

export const TYPE_LOAD = 'load';
export const TYPE_UNLOAD = 'unload';
export const TYPES = [TYPE_LOAD, TYPE_UNLOAD];


export class Job extends Model {
    static backendResourceName = 'job';

    @observable id = null;
    @observable companyName = '';
    @observable type = TYPE_LOAD;
    @observable reference = '';
    @observable instructions = '';

    @observable orderedArrivalDatetimeFrom = null;
    @observable orderedArrivalDatetimeUntil = null;

    @observable createdAt = null;
    @observable updatedAt = null;
    @observable ordering = 0;

    @observable saveAddress = false; // Helper to save company-address pair when saving job model

    @computed get readOnly() {
        if (this.order == null) {
            return false;
        }

        return this.order.isPlanned;
    }

    @computed get firstActivity() {
        if (this.activities == null || this.activities.models?.length === 0) {
            return null;
        }

        return this.activities.models[0];
    }

    @computed get lastActivity() {
        if (this.activities == null || this.activities.models?.length === 0) {
            return null;
        }

        return this.activities.models[this.activities.length - 1];
    }

    relations() {
        return {
            activities: ActivityStore,
            order: Order,
            location: Location,
            tasks: TaskStore,
        };
    }

    casts() {
        return {
            orderedArrivalDatetimeFrom: Casts.datetime,
            orderedArrivalDatetimeUntil: Casts.datetime,
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }

    setInput(field, value) {
        if (field === 'location' || field === 'companyName') {
            // Mark save address as touched.
            // Ugly but works, just like me.
            super.setInput('saveAddress', this.saveAddress);
        }
        super.setInput(field, value);
    }
}

export class UnorderedJobStore extends Store {
    Model = Job;
    static backendResourceName = 'job';
}


export const JobStore = OrderedStore(UnorderedJobStore, 'ordering');
