export const DRAFT = 'draft';
export const PLANNED = 'planned';
export const ETA = 'eta';
export const ARRIVED = 'arrived';
export const WAITING = 'waiting';
export const STARTED = 'started';
export const FINISHED = 'finished';
export const FINALIZED = 'finalized';
export const CANCELED = 'canceled';

export const ACTIVITY_STATUSES_EXPAND_ROW = [DRAFT, PLANNED, ETA, ARRIVED, WAITING, STARTED, FINISHED];
export const ACTIVITY_STATUSES = [DRAFT, PLANNED, ETA, ARRIVED, WAITING, STARTED, FINISHED, FINALIZED, CANCELED];

export const getActivityStatusColor = (status) => {
    switch (status) {
        case ETA:
            return 'var(--orange-50)'
        case ARRIVED:
            return 'var(--orange-100)'
        case WAITING:
            return 'var(--red-50)'
        case STARTED:
            return 'var(--yellow-50)'
        case FINISHED:
            return 'var(--purple-50)'
        case FINALIZED:
            return 'var(--green-50)'
        case CANCELED:
            return 'var(--red-100)'
        default:
            return 'var(--white)';
    }
}

export const getOrderActivityStatusColor = (status) => {
    switch (status) {
        case DRAFT:
            return 'var(--gray-300)'
        case ETA:
            return 'var(--orange-300)'
        case ARRIVED:
            return 'var(--orange-500)'
        case WAITING:
            return 'var(--red-300)'
        case PLANNED:
            return 'var(--blue-300)'
        case STARTED:
            return 'var(--yellow-300)'
        case FINISHED:
            return 'var(--purple-300)'
        case FINALIZED:
            return 'var(--green-300)'
        case CANCELED:
            return 'var(--red-300)'
        default:
            return 'var(--gray-300)'
    }
}

export default { DRAFT, PLANNED, ARRIVED, ETA, WAITING, STARTED, FINISHED, FINALIZED, CANCELED }
