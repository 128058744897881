import { Model, Store } from 'store/Base';
import { computed, observable } from 'mobx';
import { DocumentStore } from './DocumentCustomer';
import { ContactStore } from './Contact';
import { CustomerLocationStore } from './CustomerLocation';
import { CustomerRouteStore } from './Customer/CustomerRoute';
import { User } from './User';

export class Customer extends Model {
    static backendResourceName = 'customer';

    @observable id = null;
    @observable name = '';
    @observable reference = '';
    @observable phone = '';
    @observable emailAddress = ''
    @observable chamberOfCommerce = '';
    @observable vatCode = '';
    @observable iban = '';
    @observable phone = '';
    @observable emailFromName = '';
    @observable emailFromAddress = '';
    @observable emailFromNameDocumentsInvoices = '';
    @observable emailFromAddressDocumentsInvoices = '';
    @observable paymentTerm = 60;
    @observable creditLimitAmount = 1000000; // in cents
    @observable creditLimitExpiryDate = null;
    @observable visitingAddress = '';
    @observable visitingNumber = '';
    @observable visitingZipCode = '';
    @observable visitingCity = '';
    @observable visitingCountry = '';

    @observable invoicingAddress = '';
    @observable invoicingNumber = '';
    @observable invoicingZipCode = '';
    @observable invoicingCity = '';
    @observable invoicingCountry = '';
    @observable contactType = null;
    @observable emailAddressUpdate ='';
    @observable debtorNumber = '';
    @observable blacklisted = false;
    @observable personalizeAttachment = null;

    @observable portalEnabled = false;
    @observable portalShowInvoices = false;

    @observable createdAt = null;
    @observable updatedAt = null;

    @observable creditLimitUsed = 0;

    @computed get currentCreditLeft() {
        return this.creditLimitAmount - this.creditLimitUsed;
    }


    relations() {
        return {
            documents: DocumentStore,
            contacts: ContactStore,
            locations: CustomerLocationStore,
            customerRoutes: CustomerRouteStore,
            portalUser: User,
        };
    }

    async getRecipients(typePriorityList) {
        const contactStore = new ContactStore({
            params: {
                '.email_address:not': '',
                '.customer.id': this.id
            },
        });

        let result = [];
        let i = 0

        while (i < typePriorityList.length) {
            contactStore.params['.type'] = typePriorityList[i];
            await contactStore.fetch();
            result = contactStore.map(c => c.emailAddress);
            if (result && result.length > 0) {
                break;
            }
            i++;
        }

        return result;
    }

}

export class CustomerStore extends Store {
    Model = Customer;
    static backendResourceName = 'customer';

    login(token) {
        this.__pendingRequestCount += 1;

        // Spider doesnt pass options to actual xhr, so Im passing Authorization header this way.
        this.api.defaultHeaders['Authorization'] = `Bearer ${token}`;
        const res = this.api
            .post(`${this.url()}login/`, {
                token
            })
            .then(() => {
                this.__pendingRequestCount -= 1;
            })
            .catch(err => {
                this.__pendingRequestCount -= 1;
                throw err;
            });

        // We don't need that anymore, since we got session from django
        delete this.api.defaultHeaders['Authorization'];
        return res;
    }
}
