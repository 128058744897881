import { BlueLink } from 'component/Compact/BlueLink';
import { CancelButton, SaveButton } from 'component/Compact/Button';
import Component from 'component/Component';
import HistoryFeed from 'component/History/HistoryItem';
import { FormLabel, TargetDateTimePicker, TargetSelect, TargetTextArea, TargetTextInput } from 'component/Target';
import { Button } from 'semantic-ui-react';
import ViewEdit from 'component/Views/ViewEdit';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'semantic-ui-react';
import { RightDivider } from '@code-yellow/spider';
import { ActivityStore } from 'store/Activity';
import { BookingStore } from 'store/Booking';
import { TASK_STATUSES } from 'store/enums/TaskStatus';
import { InvoiceStore } from 'store/Invoice';
import { OrderStore } from 'store/Order';
import { PurchaseInvoiceStore } from 'store/PurchaseInvoice';
import { ServiceStore } from 'store/Service';
import { Task, TaskStore } from 'store/Task';
import { TrailerStore } from 'store/Trailer';
import { TruckStore } from 'store/Truck';
import { UserStore } from 'store/User';
import { t } from '../../i18n';

@observer
export default class TaskEdit extends Component {
    static propTypes = {
        task: PropTypes.instanceOf(Task).isRequired,
        afterSave: PropTypes.func.isRequired,
        disableRelations: PropTypes.bool,
    };

    static defaultProps = {
        disableRelations: false,
    }

    userStore = new UserStore({});

    // Tags
    tagTrucks = new TruckStore();
    tagTrailers = new TrailerStore();
    tagTasks = new TaskStore();
    tagOrders = new OrderStore();
    tagInvoices = new InvoiceStore();
    tagBookings = new BookingStore();
    tagActivities = new ActivityStore();
    tagServices = new ServiceStore();
    tagPurchaseInvoices = new PurchaseInvoiceStore();

    save = () => {
        const { task, afterSave } = this.props;

        return task
            .save({ onlyChanges: true })
            .then(() => afterSave({ goBack: true }));
    };

    cancel = action(() => {
        const { task } = this.props;
        return task
            .clear();
    })

    markAsDone = () => {
        const { task } = this.props;
        task.setInput('status', 'done');
        this.save();
    }

    _renderMainContent() {
        const { task, afterSave } = this.props;

        return (
            <Form>
                <TargetTextInput required
                    target={task}
                    name="subject"
                />
                <TargetDateTimePicker
                    label={t('task.field.dueDate.label')}
                    target={task}
                    name="dueDate"
                    autoComplete="off"
                />
                <TargetTextArea
                    target={task}
                    name="description"
                    autoComplete="off"
                />
                <TargetSelect search remote multiple clearable
                    searchKey=".full_name:icontains"
                    target={task}
                    name="subscribers"
                    store={this.userStore}
                    toOption={user => ({
                        value: user.id,
                        text: user.fullName,
                    })}
                />
                {!task.isNew &&
                    <Form.Field>
                        <FormLabel>{t('history.title.label')}</FormLabel>
                        <HistoryFeed object={task} afterSave={afterSave} />
                    </Form.Field>
                }
            </Form>
        )
    }

    renderTagLabel = (label, linkPrefix) => ({
        content: <BlueLink to={`${linkPrefix}${label.value}/edit`}>{label.text}</BlueLink>,
    })

    _renderSidebarContent() {
        const { task, disableRelations } = this.props;

        return (
            <Form>
                <TargetSelect search remote clearable required
                    searchKey=".full_name:icontains"
                    target={task}
                    name="assigned"
                    store={this.userStore}
                    toOption={user => ({
                        value: user.id,
                        text: user.fullName,
                    })}
                />
                <TargetSelect
                    target={task}
                    name="status"
                    options={TASK_STATUSES.map(status => ({
                        value: status,
                        text: t(`statuses.${status}`),
                    }))}
                />
                <TargetTextInput disabled
                    label={t('task.field.createdBy.label')}
                    target={task.createdBy}
                    name="fullName"
                />
                <TargetDateTimePicker disabled
                    target={task}
                    name="createdAt"
                />

                {/* TAGS */}
                <TargetSelect search remote multiple clearable
                    disabled={disableRelations}
                    searchKey=".fleet_number:icontains"
                    target={task}
                    name="tagTrucks"
                    store={this.tagTrucks}
                    toOption={truck => ({
                        value: truck.id,
                        text: `T${truck.fleetNumber}`,
                    })}
                    renderLabel={(label) => this.renderTagLabel(label, '/assets/truck/')}
                />
                <TargetSelect search remote multiple clearable
                    disabled={disableRelations}
                    searchKey=".fleet_number:icontains"
                    target={task}
                    name="tagTrailers"
                    store={this.tagTrailers}
                    toOption={trailer => ({
                        value: trailer.id,
                        text: `${trailer.fleetNumber}`,
                    })}
                    renderLabel={(label) => this.renderTagLabel(label, '/assets/trailer/')}
                />
                <TargetSelect search remote multiple clearable
                    disabled={disableRelations}
                    searchKey=".id"
                    target={task}
                    name="tagTasks"
                    store={this.tagTasks}
                    toOption={task => ({
                        value: task.id,
                        text: `TA${task.id}`,
                    })}
                    renderLabel={(label) => this.renderTagLabel(label, '/account/task/')}
                />
                <TargetSelect search remote multiple clearable
                    disabled={disableRelations}
                    searchKey=".order_number:icontains"
                    target={task}
                    name="tagOrders"
                    store={this.tagOrders}
                    toOption={order => ({
                        value: order.id,
                        text: `O${order.orderNumber}`,
                    })}
                    renderLabel={(label) => this.renderTagLabel(label, '/orders/')}
                />
                <TargetSelect search remote multiple clearable
                    disabled={disableRelations}
                    searchKey=".invoice_number:icontains"
                    target={task}
                    name="tagInvoices"
                    store={this.tagInvoices}
                    toOption={invoice => ({
                        value: invoice.id,
                        text: `I${invoice.invoiceNumber}`,
                    })}
                    renderLabel={(label) => this.renderTagLabel(label, '/admin/invoice/')}
                />
                <TargetSelect search remote multiple clearable
                    disabled={disableRelations}
                    searchKey=".booking_reference:icontains"
                    target={task}
                    name="tagBookings"
                    store={this.tagBookings}
                    toOption={booking => ({
                        value: booking.id,
                        text: `B${booking.bookingReference}`,
                    })}
                    renderLabel={(label) => this.renderTagLabel(label, '/admin/booking/')}
                />
                <TargetSelect search remote multiple clearable
                    disabled={disableRelations}
                    searchKey=".id"
                    target={task}
                    name="tagActivities"
                    store={this.tagActivities}
                    toOption={activity => ({
                        value: activity.id,
                        text: `A${activity.id}`,
                    })}
                />
                <TargetSelect search remote multiple clearable
                    disabled={disableRelations}
                    searchKey=".id"
                    target={task}
                    name="tagServices"
                    store={this.tagServices}
                    toOption={service => ({
                        value: service.id,
                        text: `S${service.id ?? 'New service'}`,
                    })}
                    renderLabel={(label) => this.renderTagLabel(label, '/assets/service/')}
                />
                <TargetSelect search remote multiple clearable
                    disabled={disableRelations}
                    searchKey=".id"
                    target={task}
                    name="tagPurchaseInvoices"
                    store={this.tagPurchaseInvoices}
                    toOption={purchaseInvoice => ({
                        value: purchaseInvoice.id,
                        text: `PI${purchaseInvoice.id}`,
                    })}
                    renderLabel={(label) => this.renderTagLabel(label, '/admin/purchase-invoice/')}
                />

                {/* END TAGS */}
            </Form>
        )
    }

    _renderToolbarContent() {
        const { task } = this.props;
        return (
            <>
                <CancelButton compact
                    onClick={() => { if (window.confirm(t('form.clearConfirmation'))) this.cancel() }} content={t('form.clear')}
                    loading={task.isLoading}
                />
                <RightDivider />
                <Button primary compact positive
                    icon='checkmark box'
                    content={t('task.edit.markAsDone')}
                    onClick={() => this.markAsDone()}
                    data-test-mark-as-done
                />
                <SaveButton primary compact
                    loading={task.isLoading}
                    onClick={this.save}
                />
            </>
        )
    }

    _renderTitle() {
        const { task } = this.props;
        return task.id
            ? t('task.edit.title', { id: task.id })
            : t('task.create.title');
    }

    render() {
        return (
            <ViewEdit
                renderTitle={() => this._renderTitle()}
                renderSidebarContent={() => this._renderSidebarContent()}
                renderMainContent={() => this._renderMainContent()}
                renderToolbarContent={() => this._renderToolbarContent()}>
            </ViewEdit>
        );
    }

}
