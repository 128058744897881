import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import PropTypes from 'prop-types';
import Component from 'component/Component';
import { Table, Icon } from 'semantic-ui-react';
import { groupBy, trim } from 'lodash';
import { DATETIME_FORMAT, DATETIME_SEC_FORMAT } from 'helpers';
import { Casts } from 'store/Base';
import styled from 'styled-components';
import moment from 'moment';

const TableRow = styled(Table.Row)`
    ${props => props.isOpen && `
        border-bottom: 0;
        border: 1px solid var(--gray-500);
        > td {
            border-top: 0!important;
        }
    `}
`;
const TableRowCollapse = styled(Table.Row)`
    > td {
        padding: 0px !important;

        border: 1px solid var(--gray-500);
        border-top: 0;

        table {
            border: 0px solid black !important;

            thead tr:first-child th, tr td:first-child {
                background-color: #F9FAFB !important;
            }
        }
    }
    ${props => props.isOpen === false && 'display: none;'}
`;
@observer
export default class HistoryItemLog extends Component {
    static propTypes = {
        historyItem: PropTypes.object.isRequired,
        afterSave: PropTypes.func.isRequired,
    };

    @observable isDropDownOpen = false;

    renderItemCaret = (dropDownStatus) => {
        if (dropDownStatus) {
            return <Icon name="angle up" />;
        } else {
            return <Icon name="angle down" />;
        }
    }

    onDropdownClicked = () => {
        this.isDropDownOpen = !this.isDropDownOpen;
    };

    render() {
        const { historyItem } = this.props;
        const changesGrouped = groupBy(historyItem.changes.models, change => `${change.model}|${change.oid}`);

        return (
            <>
                <TableRow onClick={() => this.onDropdownClicked()} style={{ cursor: 'pointer' }} isOpen={this.isDropDownOpen}>
                    <Table.Cell> {this.renderItemCaret(this.isDropDownOpen)} </Table.Cell>
                    <Table.Cell> {historyItem.user.fullName} </Table.Cell>
                    <Table.Cell> {historyItem.date.toFormat(DATETIME_FORMAT)} </Table.Cell>
                </TableRow>
                <TableRowCollapse isOpen={this.isDropDownOpen}>
                    <Table.Cell colspan='3' verticalAlign='middle'>
                        <Table fixed size='small' basic>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell collapsing>  </Table.HeaderCell>
                                    <Table.HeaderCell verticalAlign='middle'>{t('history.field.model.label')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('history.field.id.label')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('history.field.field.label')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('history.field.old.label')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('history.field.new.label')}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {Object.keys(changesGrouped).map(key => {
                                    const changes = changesGrouped[key]
                                    return (
                                        <>
                                            {changes.map(change => {
                                                let { field, before, after, model, oid } = change;

                                                if (before === after) {
                                                    return null;
                                                }
                                                if (moment(trim(before, '"'), 'YYYY-MM-DDTHH:mm:ss.SSSSZ', true).isValid()) {
                                                    before = Casts.datetime.parse(field, trim(before, '"')).toFormat(DATETIME_SEC_FORMAT);
                                                }
                                                if (moment(trim(after, '"'), 'YYYY-MM-DDTHH:mm:ss.SSSSZ', true).isValid()) {
                                                    after = Casts.datetime.parse(field, trim(after, '"')).toFormat(DATETIME_SEC_FORMAT);
                                                }
                                                if (field === 'updated_at'){
                                                    return null
                                                }

                                                return (
                                                    <Table.Row key={change.id}>
                                                        <Table.Cell />
                                                        <Table.Cell>{model}</Table.Cell>
                                                        <Table.Cell>{oid}</Table.Cell>
                                                        <Table.Cell>{field}</Table.Cell>
                                                        <Table.Cell>{before}</Table.Cell>
                                                        <Table.Cell>{after}</Table.Cell>
                                                    </Table.Row>
                                                )
                                            })}
                                        </>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                    </Table.Cell>
                </TableRowCollapse>
            </>
        )
    }
}
