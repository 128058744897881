import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';

export class TruckPosition extends Model {
    static backendResourceName = 'truck_position';

    @observable id = null;
    @observable dataExternalID = null;
    @observable dataSource = null;
    @observable point = { lng: '', lat: '' };
    @observable measuredAt = null;
    @observable odometer = 0;

    casts() {
        return {
            measuredAt: Casts.datetime,
        };
    }
}

export class TruckPositionStore extends Store {
    Model = TruckPosition;
    static backendResourceName = 'truck_position';
}

