import { Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { Order } from './Order';
import { Surcharge } from './Surcharge/Surcharge';


export class OrderSurcharge extends Model {
    static backendResourceName = 'order_surcharge';

    @observable id = null;
    @observable adaptedAmount = null;
    @observable visibleOnInvoice = true;
    @observable enabled = true;

    /**
     * amount is the amount of the surcharge or the adaptedAmount for the order if that exists
     */
    get amount() {
        // If there is an adapted amount always use that
         if (this.adaptedAmount){
             return this.adaptedAmount
         }

         // Needs to have a surcharge
         if (!this.surcharge){
             return null;
         }

         return this.surcharge._amount;
    }

    relations() {
        return {
            order: Order,
            surcharge: Surcharge,
        };
    }
}

export class OrderSurchargeStore extends Store {
    Model = OrderSurcharge;
    static backendResourceName = 'order_surcharge';

    comparator =(a,b) => {
        if (!a.surcharge?.code){
            return 1
        }
        return a.surcharge?.code - b.surcharge?.code
    }
}
