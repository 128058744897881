import { Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { Truck } from './Truck';

export class TruckDataSource extends Model {
    static backendResourceName = 'truck_data_source';

    @observable id: number | null = null;
    @observable dataSource = '';
    @observable dataExternalId = '';
    @observable activePosition = false;
    @observable activeEta = false;
    @observable activeMessage = false;

    @observable truck = this.relation(Truck);
}

export class TruckDataSourceStore extends Store {
    Model = TruckDataSource;
    static backendResourceName = 'truck_data_source';
}
