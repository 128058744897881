import i18next from 'i18next';

// workaround to fix typo warning -- Property 't' does not exist on type 'Window & typeof globalThis'. --
declare global {
   interface Window {
     t: any;
   }
 }

// @ts-ignore
// export const i18next: typeof i18next = window.i18next || i18next;
// As order of window.t initialization cannot be guaranteed, we need to evaluate it on demand
export const t = (key, options?) => {
    if (window.t) {
        return window.t(key, options)
     } else {
        return i18next.t(key, options);
     }
}
