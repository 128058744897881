import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Button, TopMenu, MenuRow, NavMenu, NavItem, Logo } from 're-cy-cle';
import { Header, Modal, Icon, Image } from 'semantic-ui-react';
import { Route, withRouter } from 'react-router-dom';
import ImgLogoBlack from 'image/logo_dalessi_black.svg';
import { SmallAvatar } from 'component/UserAvatar';
import { BUILD_INFO } from 'helpers';
import { hasPermission, isDriverUser, isCustomerPortalUser } from 'helpers/currentUser';
import { t } from 'i18n';
import { ModuleRepository } from '_iae/module/repository';

const LogoBlack = () => (
    <Logo>
        <Image src={ImgLogoBlack} width="100" alt="logo" />
    </Logo>
);

@withRouter
@observer
export default class AppHeader extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        moduleRepository: PropTypes.instanceOf(ModuleRepository).isRequired,
    };

    @observable debug = false;
    @observable showLogoutModal = false;

    toggleDebug = () => {
        this.debug = !this.debug;

        if (this.debug) {
            localStorage.setItem('debug', true);
        } else {
            localStorage.removeItem('debug');
        }
    }

    constructor(...args) {
        super(...args);
        this.debug = !!localStorage.getItem('debug');
    }

    _renderDashboard = (metabases) => {
        // iterate over each dashboard to be rendered and add an navitem for that dashboard
        if (metabases && metabases.length > 0) {
            return (<NavMenu>
                {metabases.map((metabase) => {
                    return (
                        <NavItem
                            title={metabase.name}
                            onClick={() => {
                                window.location.pathname = 'dashboard/overview/' + metabase.id.toString() + '/view'
                            }}

                            to={'/dashboard/overview/' + metabase.id.toString() + '/view'}
                            activePath={'/dashboard/overview/' + metabase.id.toString() + '/view'}
                            path='/dashboard'
                        />
                    )
                })}
            </NavMenu>
            )
        }
    }

    _renderCustomerPortal = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.customerPortal.ordersOverview')}
                    to="/customer-portal/orders/overview"
                    activePath="/customer-portal/orders/overview"
                />
            </NavMenu>
        );
    };

    _renderOrderManagement = () => {
        return (
            <NavMenu data-test-menu-inf>
                {hasPermission([
                    'order.manage_order'
                ]) && (
                        <NavItem
                            title={t('nav.orderManagement.entry')}
                            to="/orders/add"
                            activePath="/orders/add"
                        />
                    )}
                <NavItem
                    title={t('nav.orderManagement.overview')}
                    to="/orders/overview"
                    activePath="/orders/overview"
                />
                {hasPermission([
                    'order.manage_order'
                ]) && (
                        <NavItem
                            title={t('nav.orderManagement.forwarding')}
                            to="/orders/forwarding"
                            activePath="/orders/forwarding"
                        />
                    )}
            </NavMenu>
        );
    };

    _renderAdmin = () => {
        return (
            <NavMenu data-test-menu-inf>
                {hasPermission(['customer.manage_customer', 'customer.manage_customer:planning']) && <NavItem
                    title={t('nav.assets.customer')}
                    to="/admin/customer/overview"
                    activePath="/admin/customer/"
                />}
                {/* {<NavItem
                    title={t('nav.admin.surcharge')}
                    to="/admin/surcharge/overview"
                    activePath="/admin/surcharge/"
                />} */}
                {hasPermission(['finance.manage_truckingcompanycontract']) && <NavItem
                    title={t('nav.admin.truckingCompanyContract')}
                    to="/admin/trucking-company-contract/overview"
                    activePath="/admin/trucking-company-contract/"
                />}
                {hasPermission(['operation.manage_tripactivities']) && <NavItem
                    title={t('nav.admin.tripActivity')}
                    to="/admin/trip-activity/overview"
                    activePath="/admin/trip-activity/"
                />}
                {hasPermission(['operation.manage_trip']) && <NavItem
                    title={t('nav.assets.trip')}
                    to="/admin/trip/overview"
                    activePath="/admin/trip/"
                />}
                {hasPermission(['operation.manage_booking']) && <NavItem
                    title={t('nav.assets.booking')}
                    to="/admin/booking/overview"
                    activePath="/admin/booking/"
                />}
                {hasPermission(['admin.manage_invoice']) && <NavItem
                    title={t('nav.admin.invoice')}
                    to="/admin/invoice/overview"
                    activePath="/admin/invoice"
                />}
                {hasPermission(['finance.manage_purchaseinvoice']) && <NavItem
                    title={t('nav.admin.purchase_invoice')}
                    to="/admin/purchase-invoice/overview"
                    activePath="/admin/purchase-invoice"
                />}
                {hasPermission(['finance.manage_surcharge']) && <NavItem
                    title={t('nav.admin.surcharge')}
                    to="/admin/surcharge/overview"
                    activePath="/admin/surcharge"
                />}
                <NavItem
                    title={t('nav.admin.service')}
                    to="/admin/service/overview"
                    activePath="/admin/service/"
                />
            </NavMenu>
        );
    };

    _renderData = () => {
        return (
            <NavMenu data-test-menu-inf>
                <NavItem
                    title={t('nav.assets.trailer')}
                    to="/assets/trailer/overview"
                    activePath="/assets/trailer/"
                />
                <NavItem
                    title={t('nav.assets.truck')}
                    to="/assets/truck/overview"
                    activePath="/assets/truck/"
                />
                <NavItem
                    title={t('nav.assets.truckingCompany')}
                    to="/assets/trucking-company/overview"
                    activePath="/assets/trucking-company/"
                />
                <NavItem
                    title={t('nav.assets.terminalTransporter')}
                    to="/assets/terminal-transporter/overview"
                    activePath="/assets/terminal-transporter/"
                />
                <NavItem
                    title={t('nav.assets.costCalculation')}
                    to="/assets/cost-calculation/overview"
                    activePath="/assets/cost-calculation/"
                />
                <NavItem
                    title={t('nav.assets.driver')}
                    to="/assets/driver/overview"
                    activePath="/assets/driver/"
                />
                <NavItem
                    title={t('nav.assets.district')}
                    to="/assets/district/overview"
                    activePath="/assets/district/"
                />
                <NavItem
                    title={t('nav.assets.serviceType')}
                    to="/assets/service-type/overview"
                    activePath="/assets/service-type/"
                />
                <NavItem
                    title={t('nav.assets.goodsDescription')}
                    to="/assets/goods-description/overview"
                    activePath="/assets/goods-description/"
                />
                {hasPermission(['assets.manage_metabase']) && <NavItem
                    title={t('nav.assets.metabase')}
                    to="/assets/metabase/overview"
                    activePath="/assets/metabase/"
                />}
                {hasPermission(['admin.manage_invoice']) && <NavItem
                    title={t('nav.assets.globalValues')}
                    to="/assets/global-value/overview"
                    activePath="/assets/global-value/overview"
                />}
            </NavMenu>
        );
    };

    _renderAccountMenu = () => {
        const { store } = this.props;
        const { version, branch } = BUILD_INFO;

        return (
            <NavItem
                title={
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        <SmallAvatar user={store.currentUser} />
                        {' '}
                        {store.currentUser.fullName} (
                        {branch && branch !== 'production' && branch + ' '}
                        {version}
                        )
                    </span>
                }
                to="/account/details"
                activePath="/account/"
            />
        );
    };

    _renderAccount = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.account.account')}
                    to="/account/details"
                />
                {hasPermission([
                    'task.view_task:all'
                ]) && (
                        <NavItem
                            title={t('nav.assets.tasks')}
                            to="/account/task/overview"
                            activePath="/account/task"
                        />
                    )}
                {hasPermission([
                    'assets.view_user:employees', 'assets.view_user:customer_portal'
                ]) &&
                    <NavItem
                        title={t('nav.assets.users')}
                        to="/account/user/overview"
                        activePath="/account/user/"
                    />}
                {hasPermission([
                    'operation.manage_trip'
                ]) && (
                        <>
                            <NavItem
                                title={t('nav.integration.integrationLogger')}
                                to="/account/integration-logger"
                            />
                            <NavItem
                                title={t('nav.unit4.integrationLogger')}
                                to="/account/unit4"
                            />
                        </>
                    )}
                {hasPermission([
                    'customer.manage_customer'
                ]) && (
                        <>
                            <NavItem
                                title={t('nav.assets.communicationEmailTemplate')}
                                to="/account/email-template/overview"
                                activePath="/account/email-template/"
                            />
                            <NavItem
                                title={t('nav.assets.activityStatusEmailTemplate')}
                                to="/account/activity-status-email-template/overview"
                                activePath="/account/activity-status-email-template/"
                            />
                        </>
                    )}

                <React.Fragment>
                    {!isCustomerPortalUser() && <NavItem title={t('nav.account.changelog')} to="/account/changelog" />}
                </React.Fragment>

            </NavMenu>
        );
    };


    render() {
        const { currentUser } = this.props.store;
        const { moduleRepository } = this.props;

        if (!this.props.store.isAuthenticated || (
            currentUser.groupNames === undefined &&
            !currentUser.hasSuperpowers
        )) {
            return (
                <TopMenu>
                    <MenuRow>
                        <LogoBlack />
                    </MenuRow>
                    <MenuRow />
                </TopMenu>
            );
        }

        const logoutModal = (
            <Modal closeIcon open={this.showLogoutModal} onClose={() => this.showLogoutModal = false} basic
                size='small'>
                <Header icon='archive' content={t('user.account.logoutButton')} />
                <Modal.Content>
                    <p>
                        {t('user.account.logout.confirm')}
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic color='red' inverted onClick={() => this.showLogoutModal = false}>
                        <Icon name='remove' /> {t('form.no')}
                    </Button>
                    <Button color='green' inverted onClick={() => {
                        this.props.store.performLogout().then(() => {
                            this.showLogoutModal = false;
                        });
                    }}>
                        <Icon name='checkmark' /> {t('form.yes')}
                    </Button>
                </Modal.Actions>
            </Modal>
        );

        // Obtain all the metabases to be rendered in the app header
        const metabases = currentUser.getMetabases()
        let menu = (<></>);

        if (isDriverUser()) {
            return menu;
        }

        if (isCustomerPortalUser()) {
            menu = (
                <TopMenu>
                    <MenuRow>
                        <LogoBlack />
                        <NavMenu>
                            <NavItem
                                title={t('nav.customerPortal.label')}
                                to={'/customer-portal/orders/overview'}
                                activePath="/customer-portal"
                            />
                        </NavMenu>
                        {this._renderAccountMenu()}
                    </MenuRow>
                    <MenuRow>
                        <Route path="/customer-portal" render={this._renderCustomerPortal} />
                        <Route path="/account" render={this._renderAccount} />
                    </MenuRow>
                </TopMenu>
            )
        } else {
            menu = (
                <TopMenu>
                    <MenuRow>
                        <LogoBlack />
                        <NavMenu>
                            {hasPermission([
                                'order.manage_order'
                            ]) && (
                                    <NavItem
                                        title={t('nav.orderManagement.label')}
                                        to={hasPermission(['order.manage_order']) ? '/orders/add' : '/orders/overview'}
                                        activePath="/orders"
                                    />
                                )}
                            {hasPermission(['operation.manage_trip']) && (
                                <NavItem
                                    title={t('nav.salesPlan.label')}
                                    to="/sales-plan"
                                    activePath="/sales-plan"
                                />
                            )}
                            {hasPermission(['operation.manage_trip']) && (
                                <NavItem
                                    title={t('nav.capacityPlan.label')}
                                    to="/capacity-plan"
                                    activePath="/capacity-plan"
                                />
                            )}
                            {hasPermission(['operation.manage_trip']) && (
                                <NavItem
                                    title={t('nav.truckPlanning.label')}
                                    to="/truck-planning"
                                    activePath="/truck-planning"
                                />
                            )}
                            {hasPermission(['customer.manage_customer', 'customer.manage_customer:planning']) && (
                                <NavItem
                                    title={t('nav.admin.label')}
                                    to="/admin/customer/overview"
                                    activePath="/admin"
                                />
                            )}
                            {hasPermission(['assets.manage_transporter']) && (
                                <NavItem
                                    title={t('nav.data.label')}
                                    to="/assets/trailer/overview"
                                    activePath="/assets"
                                />
                            )}
                            {metabases.length > 0 && (
                                <NavItem
                                    title={t('nav.metabase.label')}
                                    to={`/dashboard/overview/${metabases.at(0).id.toString()}/view`}
                                    activePath="/dashboard"
                                />
                            )}
                            {currentUser.isSuperuser && moduleRepository.modules.filter(module => module.navigationMenu).map(module => {
                                return module.navigationMenu().topMenuItem;
                            })}
                        </NavMenu>
                        {this._renderAccountMenu()}
                    </MenuRow>
                    <MenuRow>
                        <Route path="/orders" render={this._renderOrderManagement} />
                        <Route path="/account" render={this._renderAccount} />
                        <Route path="/assets" render={this._renderData} />
                        <Route path="/admin" render={this._renderAdmin} />
                        <Route path="/dashboard" render={() => { return this._renderDashboard(metabases) }} />
                        {currentUser.isSuperuser && moduleRepository.modules.filter(module => module.navigationMenu).map(module => {
                            return module.navigationMenu().subMenuItems;
                        })}
                    </MenuRow>
                </TopMenu>
            )
        }

        return (
            <React.Fragment>
                {menu}
                {logoutModal}
            </React.Fragment>
        );
    }
}
