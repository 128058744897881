export const TYPE_DAYSHIFT = 'dayshift';
export const TYPE_NIGHTSHIFT = 'nightshift';
export const TYPE_ADMINISTRATION = 'administration';
export const TYPE_INVOICE = 'invoice';
export const TYPE_SEPARATE_POD = 'separatepod';
export const TYPE_GENERAL = 'general';
export const TYPE_OPERATION = 'operation';
export const TYPE_UPDATE = 'update';
export const TYPE_CUSTOMS = 'customs';

export const CONTACT_TYPES = [
    TYPE_DAYSHIFT,
    TYPE_NIGHTSHIFT,
    TYPE_INVOICE,
    TYPE_OPERATION,
    TYPE_ADMINISTRATION,
    TYPE_GENERAL,
];

export const CONTACT_CUSTOMER = [
    TYPE_GENERAL,
    TYPE_INVOICE,
    TYPE_SEPARATE_POD,
    TYPE_UPDATE,
    TYPE_CUSTOMS,
];

export default { TYPE_DAYSHIFT, TYPE_NIGHTSHIFT, TYPE_ADMINISTRATION, TYPE_INVOICE, TYPE_SEPARATE_POD, TYPE_GENERAL, TYPE_OPERATION, TYPE_UPDATE, TYPE_CUSTOMS }
