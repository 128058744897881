import { Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { CustomerRoute } from './CustomerRoute';
import { Surcharge } from '../Surcharge/Surcharge';

export class CustomerRouteSurcharge extends Model {
    static backendResourceName = 'customer_route_surcharge';

    @observable id = null;
    @observable adaptedAmount = null;
    @observable visibleOnInvoice = true;
    @observable enabled = true;

    /**
     * amount is the amount of the surcharge or the adaptedAmount for the customerRoute if that exists
     */
    get amount() {
         if (this.adaptedAmount){
             return this.adaptedAmount
         }

         // Needs to have a surcharge
         if (!this.surcharge){
             return null;
         }

         return this.surcharge._amount;
    }

    relations() {
        return {
            customerRoute: CustomerRoute,
            surcharge: Surcharge,
        };
    }
}

export class CustomerRouteSurchargeStore extends Store {
    Model = CustomerRouteSurcharge;
    static backendResourceName = 'customer_route_surcharge';
}
