// @ts-ignore
import React, { Component } from 'react';
import { t } from './i18n'
import enTranslations from './i18n/translation/en';
// @ts-ignore
import { NavItem } from 're-cy-cle';
import ActivityTemplateOverview from './screen/Driver/ActivityTemplate';

interface NavigationMenu {
    basePath: string;
    topMenuItem: JSX.Element;
    subMenuItems: JSX.Element[];
}

interface ModuleRoute {
    path: string;
    screen: typeof Component;
}

interface Translation {
    [key: string]: string | Translation;
}


interface ModuleInterface {
    name: string;
    // Done as a callback, such that it only renders after the translations have been loaded
    navigationMenu?: () => NavigationMenu;
    routes: ModuleRoute[];
    translations: {
        [language: string]: Translation
    }
}

export const driverAppModule: ModuleInterface = {
    name: 'driver-app-module',
    navigationMenu: () => ({
        basePath: '/driver-app/template/overview',
        topMenuItem: (
            <NavItem
                title={t('nav.driverApp.label')}
                to="/driver-app/template/overview"
                activePath="/driver-app/template/overview"
            />
        ),
        subMenuItems: []
    }),
    routes: [
        {
            path: '/driver-app/template/overview',
            screen: ActivityTemplateOverview,
        },
    ],
    translations: {
        en: enTranslations,
    }
}