import React from 'react';
import { DroppableButton as DroppableButtonBase, Button as BaseButton, SaveButton as BaseSaveButton, SendButton as BaseSendButton } from '@code-yellow/spider';
import Hotkeys from 'react-hot-keys';
import { Popup, Button } from 'semantic-ui-react';

//[TODO][SPIDER]
export { CancelButton, AddButton, BulkActionsButton, ItemButton, Link } from '@code-yellow/spider';

export class DroppableButton extends DroppableButtonBase {
    renderContent(props) {
        const extraProps = {};

        if (this.droppable) {
            extraProps.positive = true;
        } else {
            extraProps.primary = true;
        }

        return (
            <BaseButton {...props} {...extraProps}
                type="button"
                icon="upload"
            />
        );
    }
}

// Best to move to spider.
export class SaveButton extends BaseSaveButton {
    render() {
        const { shortcut, onClick } = this.props;
        const button = super.render();

        if (shortcut) {
            return (
                <Hotkeys
                    keyName={shortcut}
                    onKeyDown={onClick}
                >
                    <Popup
                        position="top right"
                        trigger={<div>{button}</div>} // need to wrap up in div else onClick is broken
                        content={`Keyboard shortcut: ${shortcut}`}
                    />
                </Hotkeys>
            );
        }

        return button;
    }
}

export class SendButton extends BaseSendButton {
    render() {
        const { shortcut, onClick, disabled } = this.props;
        const button = super.render();

        if (shortcut) {
            return (
                <Hotkeys
                    keyName={shortcut}
                    onKeyDown={() => {
                        if (!disabled) {
                            onClick();
                        }
                    }}
                >
                    <Popup
                        position="top right"
                        trigger={<div>{button}</div>} // need to wrap up in div else onClick is broken
                        content={`Keyboard shortcut: ${shortcut}`}
                    />
                </Hotkeys>
            );
        }

        return button;
    }
}

export class HotKeysButton extends Button {
    render() {
        const { shortcut, onClick } = this.props;
        const button = super.render();

        if (shortcut) {
            return (
                <Hotkeys
                    keyName={shortcut}
                    onKeyDown={onClick}
                >
                    <Popup
                        position="top right"
                        trigger={<div>{button}</div>} // need to wrap up in div else onClick is broken
                        content={`Keyboard shortcut: ${shortcut}`}
                    />
                </Hotkeys>
            );
        }

        return button;
    }
}
