// [TODO] add dutch translations
export default {
    dalessi: {
        slogan: 'Ferry Very Good',
    },
    general: {
        notAvailable: 'Not available',
        noExtraInformationAvailable: 'No extra information available',
        at: 'at',
        viewLogs: 'View logs',
        error: 'Error occurred',
        loading: 'Loading',
        canceled: 'Canceled',
        loadTrips: 'Load Trips',
        book: 'Book!',
        na: 'n/a',
        somethingWentWrong: 'Something went wrong.',
        exportInProgress: 'Export started. Large exports will take long time to finish (Limit 10000). Please wait... '
    },
    ordering: {
        1: 'first',
        2: 'second',
        3: 'third',
        4: 'fourth',
        5: 'fifth',
        6: 'sixth',
        7: 'seventh',
        8: 'eighth',
        9: 'ninth',
    },
    mail: {
        attachments: {
            title: 'Attachments'
        }
    },
    geo: {
        notFound: 'No location found!',
    },
    enums: {
        weekday: {
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            sunday: 'Sunday',
        },
        lengthUnit: {
            miles: 'Miles',
            milesShort: 'mi',
            kilometers: 'Kilometers',
            kilometersShort: 'km',
        },
        externalDataSource: {
            transics: 'Transics',
            volvo: 'Volvo',
            fleetboard: 'Fleetboard',
            driver_app: 'Driver app',
            other: 'Other',
        },
        costCalculationVariation: {
            exchange_rate: 'Exchange Rate',
            europe_mainland: 'Europe Mainland',
            north_uk: 'North UK',
            south_uk: 'South UK',
            ireland: 'Ireland',
        },
        correctionType: {
            daily_rate: 'Daily Rate',
            other_costs: 'Other Costs',
            road_pricing: 'Road Pricing',
        },
        personalizeAttachment: {
            invoice_only: 'Invoice document only',
            pod_only: 'POD documents only',
            invoice_pod_sent_separately: 'Invoice and POD to be sent separately to different e-mails',
            invoice_pod_merged_in_one_file: 'Invoice and POD merged in one file',
        }
    },
    target: {
        distanceTargetInput: {
            defaultLabel: 'Distance'
        }
    },
    nav: {
        customerPortal: {
            label: 'Customer Portal',
            ordersOverview: 'Orders overview',
        },
        integration: {
            integrationLogger: 'Integration logs'
        },
        unit4: {
            integrationLogger: 'Unit4 integrations'
        },
        orderManagement: {
            label: 'Orders',
            entry: 'Order entry',
            overview: 'Overview',
            forwarding: 'Forwarding'
        },
        salesPlan: {
            label: 'Sales',
        },
        subcontracting: {
            label: 'Forwarding',
        },
        forwarding: {
            label: 'Forwarding',
            screen: 'Screen',
            orders: 'Orders',
        },
        capacityPlan: {
            label: 'Trailers',
        },
        truckPlanning: {
            label: 'Trucks',
        },
        admin: {
            label: 'Administration',
            invoice: 'Invoices',
            tripActivity: 'Trip Activities',
            purchase_invoice: 'Purchase Invoices',
            orderSubcontract: 'Subcontracts',
            truckingCompanyContract: 'Trucking contracts',
            surcharge: 'Surcharges',
            service: 'Service & maintenance',
        },
        data: {
            label: 'Data configuration'
        },
        main: {
            assets: 'Master data',
            system: 'System',
        },
        metabase:{
            label: 'Dashboards'
        },
        assets: {
            route: 'Schedules',
            transporter: 'Transporters',
            truckingCompany: 'Transport Company',
            terminalTransporter: 'Terminal Transporters',
            terminal: 'Terminals',
            trip: 'Trip overview',
            schedule: 'Schedules',
            label: 'Assets',
            users: 'Users',
            truck: 'Trucks',
            trailer: 'Trailers',
            globalValues: 'Settings',
            globalFiles: 'Special files',
            order: 'Orders',
            customer: 'Customers',
            invoice: 'Invoices',
            booking: 'Ferry bookings',
            district: 'Districts',
            communicationEmailTemplate: 'Email template',
            activityStatusEmailTemplate: 'Activity template',
            driver: 'Drivers',
            metabase: 'Metabase',
            tasks: 'Tasks',
            goodsDescription: 'Goods description',
            costCalculation: 'Cost calculation',
            serviceType: 'Service & maintenance settings',
            driverActivityTemplate: 'Driver Activity Template',
        },
        audit: {
            accessLog: 'Access Log',
        },
        account: {
            account: 'Account',
            changelog: 'Changelog'
        },
    },
    changelog: {
        title: {
            label: 'Changelog'
        }
    },
    error: {
        activity_no_trailer_required: 'This activity need to be executed by a truck without a trailer assigned',
        activity_no_trailer: 'This activity needs trailer to be executed',
        activity_no_truck: 'Activity cannot be started without a truck',
        activity_unfinished_previous_activities: 'Activity cannot be started when previous activities are not finished',
        activity_transitions_not_allowed: 'Transition to this status is not allowed from current status',
        cannot_cancel_activity_in_progress: 'Cannot cancel activity that is in progress',
        invalid_start_activity: 'Invalid start Activity',
        invalid_successor: 'Invalid activities order',
        cannot_swap_trailer: 'Cannot swap trailer between activities',
        invalid_pickup_unassignment: 'Cannot unassign trailer pick up activity',
        activity_finished: 'Finish previous activity before start this one',
        activity_deleted: 'This activity couldn\'t be deleted because is related to another one',
        incorrect_gmap_api_key: 'Incorrect google maps api key!',
        invalid_truck_deactivation: 'Cannot deactivate truck with attached trailer or unfinished last activity',
        invalid_truck_activation: 'Cannot activate already active truck or truck without yard location',
        valid_from_less_then_today: 'Valid from date of schedule cannot be before or be the same as todays date',
        valid_to_less_then_today: 'Valid to date of schedule cannot be before or be the same as todays date',
        wrong_valid_to: 'Valid to date of cannot be before or be the same as valid from date',
        wrong_valid_from: 'Valid from date cannot be after or be the same as valid to date of schedule',
        trip_cannot_be_planned: 'Cannot move trip as it is already executing.',
        trip_transitions_not_allowed: 'This trip status transition not allowed.',
        sales_plan_delete_not_allowed: 'Trips within this sales plan are already executing and cannot be removed.',
        missing_to_field: 'Missing recipient email address',
        service_truck_mismatch: 'This activity is intended for diffrent truck.',
    },
    entity: {
        modal: {
            sendEmail: {
                template: {
                    label: 'Template',
                },
                header: 'Send email',
                trigger: 'Send email'
            }
        }
    },
    user: {
        overview: {
            title: 'Users',
            addButton: 'Add User',
        },
        create: {
            title: 'Create User',
        },
        edit: {
            title: 'Edit User',
        },
        field: {
            fullName: {
                label: 'Name'
            },
            firstName: {
                label: 'First Name'
            },
            lastName: {
                label: 'Last Name'
            },
            email: {
                label: 'Email'
            },
            password: {
                label: 'Password',
                repeat: 'Repeat',
            },
            dateJoined: {
                label: 'Added At'
            },
            groups: {
                label: 'Groups',
                value: {
                    superuser: 'Superuser',
                    administration: 'Administration',
                    admin: 'Admin',
                    manager: 'Manager',
                    planning: 'Planning',
                    customer_portal: 'Customer portal',
                    management: 'Management',
                    customer_portal_management: 'Customer portal Management',
                    driver : 'Driver',
                },
                empty: 'No groups assigned',
            },
            language: {
                label: 'Language'
            },
            isActive: {
                label: 'Users',
                options: {
                    all: 'All',
                    yes: 'Active',
                    no: 'Not Active',
                }
            },
        },
        login: {
            title: 'Login',
            forgotPasswordLink: 'Forgot password?',
            errors: {
                invalidCredentials: 'Username or password do not match',
                unknown: 'Unknown error, status code: {{status}}',
            },
            loginButton: 'Log In',
        },
        passwordForgot: {
            title: 'Forgot password',
            requestButton: 'Send Email',
            requestedEmailText: 'An email with a reset code has been sent to this email address.',
            loggedInError: 'You are logged in. Password forgot only works when you are not logged in.',
        },
        resetPassword: {
            title: 'Reset password',
            resetButton: 'Reset',
            saveSuccess: 'Password is successfully reset.',
            loggedInError: 'You are logged in. Password reset only works when you are not logged in.',
        },
        changePassword: {
            title: 'Change Password',
            field: {
                passwordOld: {
                    label: 'Current password',
                },
                passwordNew: {
                    label: 'New password',
                },
            },
            saveButton: 'Save',
        },
        account: {
            title: 'Account Details',
            logoutButton: 'Log Out',
            info: {
                title: 'Account Info',
            },
            password: {
                title: 'Change Password',
            },
            logout: {
                title: 'Log Out',
                confirm: 'Are you sure you want to logout?'
            },
        },
    },
    myFilter: {
        custom: 'Custom',
        action: {
            unsetDefault: 'Unset Default',
            setDefault: 'Set Default',
            setCurrent: 'Save Current Filters',
            delete: 'Delete',
        },
    },
    globalValue: {
        edit: {
            title: 'Edit value of global setting',
        },
        overview: {
            title: 'Global settings',
        },
        field: {
            key: {
                label: 'Setting name',
            },
            value: {
                label: 'Value',
            },
            writable: {
                label: 'Writable?',
            },
            readable: {
                label: 'Readable?',
            },
        },
    },
    globalFile: {
        edit: {
            title: 'Edit special file',
        },
        overview: {
            title: 'Special files',
        },
        field: {
            key: {
                label: 'Name for file',
            },
            value: {
                label: 'File',
            },
            writable: {
                label: 'Writable?',
            },
            readable: {
                label: 'Readable?',
            },
        },
    },
    accessLogEntry: {
        overview: {
            title: 'Audit log',
        },
        field: {
            originalUser: {
                label: 'Original user',
            },
            masqueradingAsUser: {
                label: 'Masquerading as user',
            },
            ipAddress: {
                label: 'IP address',
            },
            method: {
                label: 'HTTP method',
            },
            endpoint: {
                label: 'Endpoint',
            },
            statusCode: {
                label: 'Status code',
            },
            additionalInfo: {
                label: 'Extra information',
            },
            requestTime: {
                label: 'Time of access',
            },
        },
    },
    tooltips: {
        masquerade: 'Masquerade',
        edit: 'Edit',
        copy: 'Copy',
        delete: 'Delete',
        add: 'Add',
        view: 'View',
        restore: 'Restore',
    },
    form: {
        fileType: {
            pdf: 'PDF',
            image: 'IMG',
            xls: 'XLS',
            any: 'Other',
            none: 'No file uploaded yet',
        },
        pdfLoading: 'Loading pdf...',
        pdfError: 'Error loading pdf...',
        sendButton: 'Send',
        saveButton: 'Save',
        saveCreateEmpty: 'Save & New',
        previewSavePdf: 'Save & Preview PDF',
        sendDraftSaveButton: 'Save & Send',
        saveClose: 'Save & Close',
        close: 'Close',
        saveCreateKeepData: 'Save & Copy',
        cancel: 'Cancel',
        clear: 'Clear',
        continue: 'Continue',
        repeat: 'Repeat',
        multiPick: {
            searchPlaceholder: 'Select…',
            selectedText: '$1 of $2 selected',
            noneSelectedText: 'None selected',
            selectAllButton: 'All',
            selectNoneButton: 'None',
        },
        notifications: {
            saveSuccess: 'Saved successfully',
            deleteSuccess: 'Deleted successfully',
            saveError: 'Error with saving ({{status}})',
            saveValError: 'Not all information is filled in correctly',
            saveAuthError: 'Invalid credentials',
            newAppVersion: 'New version available, click to reload page as soon as possible',
        },
        submitButton: 'Submit',
        cancelButton: 'Cancel',
        addButton: 'Add',
        copyButton: 'Copy',
        publishButton: 'Publish',
        startDate: 'Start Date',
        endDate: 'End Date',
        deleteConfirmation: 'Are you sure you want to delete this entity?',
        restoreConfirmation: 'Are you sure you want to restore this entity?',
        clearConfirmation: 'Are you sure you wish to clear this entity details?',
        goBackConfirmation: 'Are you sure you want to go back without saving?',
        search: 'Search',
        actions: 'Actions',
        exportButton: 'Export',
        fixedRemarks: 'Fixed remarks',
        orderRemarks: 'Order remarks'
    },
    crash: {
        heading: 'Application crashed',
        subHeading1: 'We’re very sorry, but the application has crashed.',
        subHeading2: 'Our team has been notified.',
        reload: 'Reload page'
    },
    daycy: {
        period: {
            pm: 'pm',
            am: 'am',
        },
        week: {
            label: 'Wk',
            number: '{{weekNumber}}',
        },
        weekDay: {
            monday: 'Mo',
            tuesday: 'Tu',
            wednesday: 'We',
            thursday: 'Th',
            friday: 'Fr',
            saturday: 'Sa',
            sunday: 'Su',
        },
        month: {
            january: 'January',
            february: 'February',
            march: 'March',
            april: 'April',
            may: 'May',
            june: 'June',
            july: 'July',
            august: 'August',
            september: 'September',
            october: 'October',
            november: 'November',
            december: 'December',
        },
    },
    pageNotFoundMessage: 'Page not found',
    pageNotAllowedMessage: 'You are not allowed to see this page',
    brandingMessage: 'Powered by Code Yellow',

    job: {
        create: {
            title: 'Add Job'
        },
        field: {
            type: {
                label: 'Type',
                value: {
                    load: 'Load',
                    unload: 'Unload',
                },
            },
            location: {
                label: 'Location',
                placeholder: 'Choose a location',
            },
            orderedArrivalDatetimeFrom: {
                label: 'Loading Date From',
                labelByType: {
                    load: 'Loading Date From',
                    unload: 'Unloading Date From',
                }
            },
            orderedArrivalDatetimeUntil: {
                label: 'Loading Date Until',
                labelShort: 'Until',
                labelByType: {
                    load: 'Loading Date Until',
                    unload: 'Unloading Date Until',
                }
            },
            reference: {
                label: 'Reference',
                placeholder: 'Transport reference',
            },
            instructions: {
                label: 'Instructions',
                placeholder: 'Transport instructions',
            },
            companyName: {
                label: 'Company name'
            },
        },
        customerLocation: {
            save: {
                on: 'Save company-address combination for customer: ON',
                off: 'Save company-address combination for customer: OFF'
            }
        }
    },
    activity: {
        overview: {
            proofOfDelivery: 'POD'
        },
        saveAndFinalizeButton: 'Save & Finalize',
        skipValidation: 'Skip Validation',
        buttons: {
            finalize: 'Finalize activity',
            create: 'Add activity',
            calculate: 'Trigger calculate Km',
        },
        changeStatusModal: {
            header: 'Change activity status',
        },
        statuses: {
            draft: 'Draft',
            planned: 'Planned',
            eta: 'Driving',
            arrived: 'Arrived',
            waiting: 'Waiting',
            started: 'Started',
            finished: 'Finished',
            finalized: 'Finalized',
            canceled: 'Canceled',
        },
        types: {
            load: 'Load',
            loadAction: 'Loading',
            unload: 'Unload',
            unloadAction: 'Unloading',
            terminalDrop: 'Terminal drop',
            terminalDropAction: 'Dropping at Terminal',
            terminalPickUp: 'Terminal pick up',
            terminalPickUpAction: 'Picking up at Terminal',
            yardPickUpAction: 'Picking up at Yard',
            yardDropAction: 'Dropping up at Yard',
            customerActivityAction: 'Customer activity',
            dalessiActivityAction: 'Dalessi activity',
            truckPickUpAction: 'Picking up a Truck',
            truckServiceAction: 'Service',
            trailerServiceAction: 'Service',
        },
        field: {
            id: {
                label: 'ID'
            },
            bookingReference: {
                label: 'Booking Reference'
            },
            unitLicensePlate: {
                label: 'Unit License Plate'
            },
            cargoSpecification: {
                label: 'Cargo Specification'
            },
            unumber: {
                label: 'Un Number'
            },
            proofOfDelivery: {
                label: 'POD'
            },
            class19: {
                label: 'Class (1-9)'
            },
            packgroup: {
                label: 'Packgroup (1-3)'
            },
            lq: {
                label: 'LQ'
            },
            mrnNumber: {
                label: 'Mrn Number'
            },
            additionalInformation: {
                label: 'Additional Information'
            },
            type: {
                label: 'Dalessi activity type'
            },
            goodsDescription: {
                label: 'Description'
            },
            orderedArrivalDatetimeFrom: {
                label: 'Date from'
            },
            orderedArrivalDatetimeUntil: {
                label: 'Date to'
            },
            instructions: {
                label: 'Driver instructions'
            },
            location: {
                label: 'Location'
            },
            reference: {
                label: 'Reference'
            },
            remarks: {
                label: 'Remarks'
            },
            status: {
                label: 'Status'
            },
            statusDraftAt: { label: 'Draft At', },
            statusPlannedAt: { label: 'Planned At', },
            statusEtaAt: { label: 'Driving At', },
            statusArrivedAt: { label: 'Arrived At', },
            statusWaitingAt: { label: 'Waiting At', },
            statusStartedAt: { label: 'Started At', },
            statusFinishedAt: { label: 'Finished At', abbreviation: 'FA' },
            statusFinalizedAt: { label: 'Finalized At', },
            statusCanceledAt: { label: 'Canceled At', },
        },
        modal: {
            title: {
                label: 'Activity Finalize'
            },
            notification: {
                missingStatusFinalizedAt: 'Fill activity date Finished At'
            },
        },
        modalConfirmation: {
            title: {
                label: 'Confirm Activity Finalization',
            },
            description: 'This action needs to be confirmed: {{errorText}}',
        },
    },
    document: {
        field: {
            file: {
                label: 'File',
            },
        },
    },
    statuses: {
        draft: 'Draft',
        sent: 'Sent',
        new: 'New',
        quoted: 'Quoted',
        accepted: 'Accepted',
        rejected: 'Rejected',
        planned: 'Planned',
        in_progress: 'In Progress',
        completed: 'Completed',
        invoiced: 'Invoiced',
        canceled: 'Canceled',
        error: 'Error',
        not_paid: 'Not paid',
        transferred: 'Transferred',
        paid: 'Paid',
        executing: 'Executing',
        finished: 'Finished',
        finalized: 'Finalized',
        requested: 'Requested',
        cancelled: 'Cancelled',
        drafted: 'Draft',
        overdue: 'Overdue',
        booked: 'Booked',
        none: 'None',
        create: 'Create',
        modify: 'Modify',
        cancel: 'Cancel',
        eta: 'Driving',
        arrived: 'Arrived',
        waiting: 'Waiting',
        started: 'Started',
        open: 'Open',
        done: 'Done',
        unknown: 'Not known',
        not_ready: 'Not Ready',
        ready: 'Ready',
        not_invoiceable: 'Not Invoiceable',
    },
    orderSubcontract: {
        buttons: {
            cancelSubcontract: 'Cancel subcontract',
        },
        overview: {
            title: 'Forwarded orders',
            customerName: 'Cust. name',
            customerReference: 'Cust. ref.',
            loadingPlace: 'Loading',
            loadingDate: 'L. date',
            unloadingPlace: 'Unloading',
            unloadingDate: 'U. date',
            statusIn: 'Status in:',
            status: 'Status',
            salesPrice: 'S. price',
            purchasePrice: 'P. price',
            orderStatus: 'Order status',
            licensePlate: 'License plate',
            truckLicensePlate: 'Truck l. plate',
            trailerLicensePlate: 'Trailer l. plate',
            remarks: 'Remarks',
        },
        create: {
            title: 'Add Subcontracted Order',
        },
        modal: {
            sendEmail: {
                recipients: {
                    label: 'Recipients',
                },
                subject: {
                    text: 'Subcontract Dalessi no. ',
                },
                content: {
                    text: 'In the attached we send you a draft of subcontract to approve.',
                },
                template: {
                    label: 'Template',
                },
            },
        },
        edit: {
            title: 'Edit subcontracted order {{id}}',
            goodCardTitle: 'Goods',
            activityCardTitleLoad: 'Shipper',
            activityCardTitleUnload: 'Consignee',
            subcontractor: 'Subcontractor',
            pricing: 'Pricing',
            cargo: 'Cargo description',
            transportOrder: 'Transport',
        },
        field: {
            id: {
                label: 'No',
            },
            order: {
                label: 'Order id'
            },
            subcontractor: {
                label: 'Subcontractor',
            },
            truckLicensePlate: {
                label: 'Truck license plate',
            },
            trailerLicensePlate: {
                label: 'Trailer license plate',
            },
            driverName: {
                label: 'Driver name',
            },
            status: {
                label: 'Status',
            },
            name: {
                label: 'Name',
            },
            customer: {
                label: 'Customer',
                placeholder: 'Customer',
            },
            goodsDescription: {
                label: 'Goods Description',
                placeholder: 'General description about the goods',
            },
            reference: {
                label: 'Reference',
            },
            packagingAmount: {
                label: 'Amount',
            },
            packagingType: {
                label: 'Packaging',
            },
            purchasePrice: {
                label: 'Purchase price in Euro',
                labelShort: 'Purchase price',
            },
            remarks: {
                label: 'Remarks',
                placeholder: 'General remarks about the order',
            },
            salesPrice: {
                label: 'Sales price in Euro',
                labelShort: 'Sales price',
                placeholder: '€0,00,-',
            },
            paymentTerms: {
                label: 'PT in days',
            },
            weight: {
                label: 'Weight',
                placeholder: 'Total weight in KG',
            },
            marginBrutto: {
                label: 'Brutto margin',
            },
            marginPercent: {
                label: 'Percent margin',
                labelShort: 'Margin'
            },
            unNumber: {
                label: 'ADR',
            },

        },
    },
    order: {
        modal: {
            bulkForward: {
                header: 'Forward order(s) to',
                saveAndSend: 'Save & Send'
            },
            completeOrder: {
                header: 'Order completion',
                completeButton: 'Complete order'
            },
            cancelOrder: {
                header: 'Order cancel',
                description: 'Are you sure you want to cancel the order?',
                cancelButton: 'Confirm',
            },
            reactivateOrder: {
                header: 'Order reactivate',
                description: 'Are you sure you want to reactivate the order?',
            },
            creditLimitReached: {
                header: 'Credit limit reached',
                description: 'Please note that with this sales price amount, the customer will exceed its credit limit.',
                okButton: 'Ok',
                createInvoiceButton: 'Create Invoice',
                cancelOrderButton: 'Cancel Order',
            },
            subcontract: {
                header: 'Subcontract order {{orderId}}',
                subcontractor: 'Subcontractor',
                cargo: 'Cargo description',
                transportOrder: 'Transport order',
                pricing: 'Pricing',
                salesPrice: 'Sales price in Euro',
                purchasePrice: 'Purchase price in Euro',
                marginBrutto: 'Brutto margin',
                marginPercent: 'Percent margin',
                truckLicensePlate: 'Truck license plate',
                trailerLicensePlate: 'Trailer license plate',
                driverName: 'Driver name',
            },
        },
        buttons: {
            completeOrder: 'Complete order',
            cancelOrder: 'Cancel order',
            reactivateOrder: 'Reactivate order',
            createInvoice: 'Create invoice',
            viewCustomerPortalPage: 'View customer portal page',
            editInvoice: 'Edit invoice',
            subcontractOrderNew: 'New subcontract order',
            subcontractOrderEdit: 'Edit subcontracted order',
            subcontract: 'Subcontract',
            collapseAll: 'Collapse all',
        },
        bulk: {
            tableHeader: {
                price: 'Price',
                status: 'Status',
                type: 'Type',
                reference: 'Reference',
                company: 'Company',
                location: 'Location',
                dateFrom: 'Date from',
                timeUntil: 'Time until',
                instructions: 'Instructions',
            },
            bulkAction: 'Bulk action',
            forwardOrdersTo: 'Forward orders to',
            createShipment: 'Create shipment',
        },
        overview: {
            title: 'Orders overview',
            customerName: 'Cust. name',
            customerReference: 'Cust. ref.',
            invoiceReference: 'Inv. ref.',
            invoiceStatus: 'Inv. status',
            loadingPlace: 'Loading place',
            loadingAddress: 'Loading address',
            loadingDate: 'Loading date',
            unloadingPlace: 'Unloading place',
            unloadingAddress: 'Unloading address',
            unloadingDate: 'Unloading date',
            statusIn: 'Status in:',
            status: 'Status',
            subcontracted: 'Subcontracted (Sc)',
            proofOfDelivery: 'POD',
            fleetNumbers: 'Trailer no.',
            truckFleetNumbers: 'Truck fleet no.',
            truckLicensePlate: 'Truck license plate',
            trailerFleetNumber: 'Trailer fleet no.',
            trailerLicensePlate: 'Trailer license plate',
            truckLicensePlateOwner: 'Truck license own',
            truckLicensePlateSc: 'Truck license sc',
            invoiced: {
                label: 'Invoiced',
                options: {
                    all: 'All',
                    yes: 'Yes',
                    no: 'No'
                }
            },
            hasProofOfDelivery: {
                label: 'Has POD',
                shortLabel: 'POD',
                options: {
                    all: 'All',
                    yes: 'Yes',
                    no: 'No',
                },
            },
        },
        view: {
            title: 'View order {{id}}',
        },
        create: {
            title: 'Add order',
            bulkTitle: 'Order entry',
            back: 'Back',
            fromTemplate: 'Add order based on template',
        },
        createBulk: {
            title: 'Add bulk order',
            bulkTitle: 'Bulk order ID',
            back: 'Back',
        },
        edit: {
            title: 'Edit order {{id}}',
            goodCardTitle: 'Goods',
            activityCardTitleLoad: 'Shipper',
            subcontracted: 'Subcontracted in: ',
            activityCardTitleUnload: 'Consignee',
            addAddress: 'Add address',
            showJobs: 'Show jobs',
            shipmentDetails: {
                activitiesGeneration: 'Activities will be generated based on provided Jobs'
            },
            orderDocumentsTitle: 'Order documents (Transport orders, confirmations, etc.)',
            invoicingDocumentsTitle: 'Internal admin documents',
            CPInvoiceDocumentsTitle: 'Invoices',
            CPProofOfDeliveryTitle: 'Proof Of Delivery',
            linkedInvoiceDocumentsTitle: 'Documents uploaded when invoicing',
            segments: {
                order_details: 'Order details',
                original_job: 'Original job',
                shipment_details: 'Shipment details',
                cargolines: 'Cargolines',
                customs: 'Customs',
                ferry_booking: 'Ferry booking',
                documents: 'Documents',
                invoice: 'Invoice',
                order_history: 'Order history',
                emptyBox: 'Empty box',
                trips: 'Trips'
            }
        },
        delete: {
            title: 'Delete Order',
        },
        field: {
            invoice: {
                label: 'Invoice',
            },
            orderNumber: {
                label: 'Order No.'
            },
            id: {
                label: 'Order no.',
            },
            status: {
                label: 'Status',
            },
            name: {
                label: 'Name',
            },
            customer: {
                label: 'Customer',
                placeholder: 'Customer',
            },
            documents: {
                label: 'Upload documents',
            },
            podDocument: {
                label: 'Add Proof of Delivery',
            },
            invoiceReference: {
                label: 'Invoice Reference',
                labelShort: 'Inv. Ref.',
                placeholder: 'Invoice Reference',
            },
            subcontracted: {
                label: 'Subcontracted',
                shortLabel: 'Sc',
                options: {
                    all: 'All',
                    own: 'Own',
                    subcontracted: 'Sc',
                },
            },
            unit: {
                label: 'Unit',
                value: {
                    ftl: 'FTL',
                    ltl: 'LTL',
                    other: 'Other',
                },
            },
            currency: {
                label: 'Currency',
                value: {
                    euro: 'EUR',
                    pound: 'GBP',
                },
                code: {
                    euro: '€',
                    pound: '£',
                }
            },
            goodsDescription: {
                label: 'Goods Description',
                placeholder: 'General description about the goods',
            },
            cargoDescription: {
                label: 'Cargo Description',
                placeholder: 'General description about the goods',
            },
            reference: {
                label: 'Reference',
            },
            weight: {
                label: 'Weight',
                placeholder: 'Total weight in KG',
            },
            packagingAmount: {
                label: 'Amount',
            },
            packagingType: {
                label: 'Packaging Type',
            },
            unNumber: {
                label: 'ADR',
                placeholder: '123456',
            },
            remarks: {
                label: 'Remarks',
                placeholder: 'General remarks about the order',
            },
            salesPrice: {
                label: 'Price',
                placeholder: '€0,00,-',
            },
            assignedUser: {
                label: 'Assigned to'
            },
            fuelSurcharge: {
                label: 'Fuel',
                popup: {
                    true: 'Fuel: included in the price',
                    false: 'Fuel: not included in the price'
                }
            },
            customerUpdate: {
                label: 'Contact customer update',
            },
            customerCustoms: {
                label: 'Contact customer customs',
            },
            createdAt: {
                label: 'Created at',
            },
            createdBy: {
                label: 'Created by',
            },
            BulkOrderSummary:{
                label: 'Bulk order summary',
                amountLabel: 'Amount',
                loadingDateLabel: 'Loading date',
                unloadingDateLabel: 'Unloading date'
            },
            destination: {
                label: 'Destination'
            }
        },
        infoPopup:{
            invoiceReferenceTitle: 'Invoice reference',
            goodsDescriptionTitle: 'Goods description',
            lastUnloadPlaceTitle: 'Last unloading place',
            loadingFromTitle: 'Loading from',
            loadingToTitle: 'Loading to',
            finishedDateTitle: 'Finished at',
            plannedDepartureTitle: 'Planned departure',
            actualDepartureTitle: 'Actual departure',
            plannedArrivalTitle: 'Planned arrival',
            actualArrivalTitle: 'Actual arrival'
        }
    },
    customerPortalOrder: {
        modal: {
            bulkForward: {
                header: 'Forward order(s) to',
                saveAndSend: 'Save & Send'
            },
            completeOrder: {
                header: 'Order completion',
                completeButton: 'Complete order'
            },
            cancelOrder: {
                header: 'Order cancel',
                description: 'Are you sure you want to cancel the order?',
                cancelButton: 'Confirm',
            },
            reactivateOrder: {
                header: 'Order reactivate',
                description: 'Are you sure you want to reactivate the order?',
            },
            creditLimitReached: {
                header: 'Credit limit reached',
                description: 'Please note that with this sales price amount, the customer will exceed its credit limit.',
                okButton: 'Ok',
                createInvoiceButton: 'Create Invoice',
                cancelOrderButton: 'Cancel Order',
            },
            subcontract: {
                header: 'Subcontract order {{orderId}}',
                subcontractor: 'Subcontractor',
                cargo: 'Cargo description',
                transportOrder: 'Transport order',
                pricing: 'Pricing',
                salesPrice: 'Sales price in Euro',
                purchasePrice: 'Purchase price in Euro',
                marginBrutto: 'Brutto margin',
                marginPercent: 'Percent margin',
                truckLicensePlate: 'Truck license plate',
                trailerLicensePlate: 'Trailer license plate',
                driverName: 'Driver name',
            },
        },
        buttons: {
            completeOrder: 'Complete order',
            cancelOrder: 'Cancel order',
            reactivateOrder: 'Reactivate order',
            createInvoice: 'Create invoice',
            editInvoice: 'Edit invoice',
            subcontractOrderNew: 'New subcontract order',
            subcontractOrderEdit: 'Edit subcontracted order',
            subcontract: 'Subcontract',
            collapseAll: 'Collapse all',
        },
        bulk: {
            tableHeader: {
                price: 'Price',
                status: 'Status',
                type: 'Type',
                reference: 'Reference',
                company: 'Company',
                location: 'Location',
                dateFrom: 'Date from',
                timeUntil: 'Time until',
                instructions: 'Instructions',
            },
            bulkAction: 'Bulk action',
            forwardOrdersTo: 'Forward orders to',
            createShipment: 'Create shipment',
        },
        overview: {
            title: 'Orders overview',
            customerName: 'Cust. name',
            customerReference: 'Cust. ref.',
            invoiceReference: 'Inv. ref.',
            invoiceStatus: 'Inv. status',
            loadingPlace: 'Loading Place',
            loadingDate: 'Loading date',
            unloadingPlace: 'Unloading place',
            unloadingDate: 'Unloading date',
            statusIn: 'Status in:',
            status: 'Status',
            subcontracted: 'Subcontracted (Sc)',
            proofOfDelivery: 'POD',
            fleetNumbers: 'Trailer no.',
            truckFleetNumbers: 'Truck no.',
            truckLicensePlate: 'Truck license plate',
            truckLicensePlateOwner: 'Truck license own',
            truckLicensePlateSc: 'Truck license sc',
            invoiced: {
                label: 'Invoiced',
                options: {
                    all: 'All',
                    yes: 'Yes',
                    no: 'No'
                }
            },
            hasProofOfDelivery: {
                label: 'Has POD',
                shortLabel: 'POD',
                options: {
                    all: 'All',
                    yes: 'Yes',
                    no: 'No',
                },
            },
        },
        view: {
            title: 'View order {{id}}',
        },
        create: {
            title: 'Add order',
            bulkTitle: 'Order entry',
            back: 'Back',
        },
        createBulk: {
            title: 'Add bulk order',
            bulkTitle: 'Bulk order ID',
            back: 'Back',
        },
        edit: {
            title: 'Edit order {{id}}',
            goodCardTitle: 'Goods',
            activityCardTitleLoad: 'Shipper',
            subcontracted: 'Subcontracted in: ',
            activityCardTitleUnload: 'Consignee',
            addAddress: 'Add address',
            showJobs: 'Show jobs',
            shipmentDetails: {
                activitiesGeneration: 'Activities will be generated based on provided Jobs'
            },
            segments: {
                order_details: 'Order details',
                original_job: 'Original job',
                shipment_details: 'Shipment details',
                cargolines: 'Cargolines',
                customs: 'Customs',
                ferry_booking: 'Ferry booking',
                documents: 'Documents',
                invoice: 'Invoice',
                order_history: 'Order history',
                emptyBox: 'Empty box',
                trips: 'Trips'
            }
        },
        delete: {
            title: 'Delete Order',
        },
        field: {
            invoice: {
                label: 'Invoice',
            },
            orderNumber: {
                label: 'Order No.'
            },
            id: {
                label: 'Order no.',
            },
            status: {
                label: 'Status',
            },
            name: {
                label: 'Name',
            },
            customer: {
                label: 'Customer',
                placeholder: 'Customer',
            },
            documents: {
                label: 'Upload documents',
            },
            podDocument: {
                label: 'Add Proof of Delivery',
            },
            invoiceReference: {
                label: 'Invoice Reference',
                labelShort: 'Inv. Ref.',
                placeholder: 'Invoice Reference',
            },
            subcontracted: {
                label: 'Subcontracted',
                shortLabel: 'Sc',
                options: {
                    all: 'All',
                    own: 'Own',
                    subcontracted: 'Sc',
                },
            },
            unit: {
                label: 'Unit',
                value: {
                    ftl: 'FTL',
                    ltl: 'LTL',
                    other: 'Other',
                },
            },
            currency: {
                label: 'Currency',
                value: {
                    euro: 'EUR',
                    pound: 'GBP',
                },
                code: {
                    euro: '€',
                    pound: '£',
                }
            },
            goodsDescription: {
                label: 'Goods Description',
                placeholder: 'General description about the goods',
            },
            cargoDescription: {
                label: 'Cargo Description',
                placeholder: 'General description about the goods',
            },
            reference: {
                label: 'Reference',
            },
            weight: {
                label: 'Weight',
                placeholder: 'Total weight in KG',
            },
            packagingAmount: {
                label: 'Amount',
            },
            packagingType: {
                label: 'Packaging Type',
            },
            unNumber: {
                label: 'ADR',
                placeholder: '123456',
            },
            remarks: {
                label: 'Remarks',
                placeholder: 'General remarks about the order',
            },
            salesPrice: {
                label: 'Price',
                placeholder: '€0,00,-',
            },
            assignedUser: {
                label: 'Assigned to'
            },
            fuelSurcharge: {
                label: 'Fuel',
                popup: {
                    true: 'Fuel: included in the price',
                    false: 'Fuel: not included in the price'
                }
            },
            customerUpdate: {
                label: 'Contact customer update',
            },
            customerCustoms: {
                label: 'Contact customer customs',
            },
            createdAt: {
                label: 'Created at',
            },
            createdBy: {
                label: 'Created by',
            },
            BulkOrderSummary: {
                label: 'Bulk order summary',
                amountLabel: 'Amount',
                loadingDateLabel: 'Loading date',
                unloadingDateLabel: 'Unloading date'
            },
            destination: {
                label: 'Destination'
            }
        },
        infoPopup: {
            invoiceReferenceTitle: 'Invoice reference',
            goodsDescriptionTitle: 'Goods description',
            lastUnloadPlaceTitle: 'Last unloading place',
            loadingFromTitle: 'Loading from',
            loadingToTitle: 'Loading to',
            finishedDateTitle: 'Finished at',
            plannedDepartureTitle: 'Planned departure',
            actualDepartureTitle: 'Actual departure',
            plannedArrivalTitle: 'Planned arrival',
            actualArrivalTitle: 'Actual arrival'
        }
    },
    cargoLine: {
        cargoLineTitle: 'CARGO LINE {{number}}',
        cargoLineAdd: 'Add cargo line',
        field: {
            packagingAmount: {
                label: 'Amount'
            },
            packagingType: {
                label: 'Type'
            },
            weight: {
                label: 'Weight (Kg)'
            },
            adr: {
                label: 'ADR',
                value: {
                    true: 'Yes',
                    false: 'No',
                },
            },
            mrn: {
                label: 'MRN'
            },
            ucr: {
                label: 'UCR'
            },
            documentType: {
                label: 'Document Type'
            },
            expiryDate: {
                label: 'Expiry Date'
            },
            customsOfficeCode: {
                label: 'Customs Office Code',
                placeholder: 'Customs Office Code',
            },
        }
    },
    chat: {
        dataProviderNeeded: 'Chat doesn\'t support {{dataProvider}} data provider.',
        dataReferenceNeeded: 'Data provider needs truck key to connect to the truck.',
        truckLink: 'Configure it within the truck: ',
        uploadDocuments: 'Upload document',
    },
    message: {
        sendBulkMessageModal: {
            title: 'Send bulk message to {{count}} trucks',
        },
        sendMessageButton: 'Send bulk message',
        submitButton: 'Send',
        routeMessagePreText: 'Sent route to driver:',
        routeMessagePreTextWithoutRoute: 'Sent activity to driver:',
        routeMessagePreTextSendDocument: 'Requested a new document:',
        routeMessagePreTextNewScan: 'STATUS UPDATE - NEW SCAN',
        sendRouteTooltip: 'Send route to driver',
        routeAlreadySentTooltip: 'Activity already sent at {{sentAt}}',
        markFormAsReadTooltip: 'Ignore driver update',
        formMessageText: 'STATUS UPDATE - {{status}}',
        resendWithoutRoute: {
            title: 'Could not send route',
            content:
                'Fleetvisor is giving us some problems... Do you want to send the activity without route?',
        },
        routeAlreadySentWarning:
            'This route was already sent to the driver. Do you really want to send it again?',
        field: {
            id: {
                label: 'ID',
            },
            type: {
                label: 'Type',
            },
            activity: {
                label: 'Activity',
            },
            source: {
                label: 'Source',
            },
            text: {
                label: 'Message',
                placeholder: 'Enter a message…',
            },
            sendAfter: {
                label: 'Send after',
            },
            writtenAt: {
                label: 'Sent at',
            },
            seenAt: {
                label: 'Seen at',
            },
            receivedAt: {
                label: 'Received at',
            },
        },
    },
    customerRoute: {
        create: { title: 'Create a new Route' },
        excludingBTW: 'Excl. btw.',
        modal: {
            trigger: {
                disabled: 'Please save the customer before adding routes.'
            },
        },
        field: {
            name: { label: 'Name' },
            startDate: { label: 'Start Date' },
            endDate: { label: 'End Date' },
            weekNumber: { label: 'Week' },
            remarks: { label: 'Remarks' },
        }
    },
    customerRouteActivityTemplate: {
        field: {
            region: { placeholder: 'Region' },
            address: { placeholder: 'Address' },
            company: { placeholder: 'Company' },
            type: {
                load: { label: 'Load' },
                unload: { label: 'Unload' },
            }
        }
    },
    customerRouteSurcharge: {
        table: {
            title: 'Surcharge Rates',
            searchCode: 'Search codes',
            calculatedSum: 'Calculated total',
            surchargeSum: 'Suggested total',
        },
        field: {
            suggestedPrice: { label: 'Suggested Price' },
            adaptedAmount: { label: 'Adapted Price' },
            visibleOnInvoice: {
                label: 'Show in invoice',
                visible: 'Shown',
                invisible: 'Not shown',
            },
        }
    },
    orderSurcharge: {
       table: {
           title: 'Surcharges',
           calculatedSum: 'Calculated total',
           surchargeSum: 'Suggested total',
       },
        field: {
           suggestedPrice: { label: 'Suggested Price' },
           adaptedAmount: { label: 'Adapted price' },
        }
    },
    customer: {
        overview: {
            title: 'Customer overview',
            addButton: 'Add Customer',
            showOrders: 'Show orders',
        },
        create: {
            title: 'Add customer',
        },
        edit: {
            title: 'Edit customer {{name}}',
            basicInfoTitle: 'General information',
            invoicingLocationTitle: 'Invoicing address',
            customerPortalTitle: 'Customer portal',
            visitingLocationTitle: 'Visiting address',
            updateDetailsTitle: 'Update details',
            contactsTitle: 'Contacts (counts)',
            personalizeAttachment: 'Personalizing invoice attachments',
        },
        field: {
            name: {
                label: 'Name',
            },
            reference: {
                label: 'Reference',
            },
            emailAddress: {
                label: 'Email Address',
            },
            phone: {
                label: 'Phone number',
            },
            debtorNumber: {
                label: 'Debtor number',
            },
            emailFromName: {
                label: 'Customer Update "From" name'
            },
            emailFromAddress: {
                label: 'Customer Update "From" email address '
            },
            emailFromNameDocumentsInvoices: {
                label: 'Documents & Invoices "from" name',
            },
            emailFromAddressDocumentsInvoices: {
                label: 'Doc. & Invoices "from" e-mail address',
            },
            iban: {
                label: 'IBAN',
            },
            chamberOfCommerce: {
                label: 'Chamber of Commerce',
            },
            paymentTerm: {
                label: 'Payment term (days)',
            },
            amountDue: {
                label: 'Amount due',
            },
            creditLimitAmount: {
                label: 'Credit limit',
            },
            creditLimitUsed: {
                label: 'Current credit',
            },
            creditLimitExpiryDate: {
                label: 'Expiry date',
            },
            visitingAddress:
            {
                label: 'Street'
            },
            visitingNumber:
            {
                label: 'Number'
            },
            visitingZipCode: {
                label: 'Zip Code'
            },
            visitingCity: {
                label: 'City'
            },
            visitingCountry: {
                label: 'Country'
            },
            invoicingAddress:
            {
                label: 'Street'
            },
            invoicingNumber:
            {
                label: 'Number'
            },
            invoicingZipCode: {
                label: 'Zip Code'
            },
            invoicingCity: {
                label: 'City'
            },
            invoicingCountry: {
                label: 'Country'
            },
            vatCode: {
                label: 'VAT code'
            },
            documents: {
                label: 'Upload documents',
            },
            contacts: {
                label: 'Contacts'
            },
            emailAddressUpdate: {
                label: 'Email addresses'
            },
            suggestedTotal: { label: 'Suggested total' },
            calculatedTotal: { label: 'Calculated total' },
            startDate: { label: 'Start date' },
            endDate: { label: 'End date' },
            portalEnabled: {
                label: 'Portal enabled',
                options: {
                    all: 'All',
                    yes: 'Yes',
                    no: 'No',
                },
            },
            portalShowInvoices: {
                label: 'Show invoices'
            },
            portalUser: {
                label: 'Portal user email'
            },
            contact: {
                value: {
                    dayshift: 'Dayshift',
                    invoice: 'Invoice',
                    nightshift: 'Nightshift',
                    administration: 'Administration',
                    general: 'General',
                    operation: 'Operation'
                }

            },
            routes: {
                title: 'Stored Routes',
            },
            locations: {
                title: 'Stored addresses',
                button: {
                    addLocation: 'Add address'
                },
                field: {
                    name: {
                        label: 'Company',
                        placeholder: ''
                    },
                    location: {
                        label: 'Address',
                        placeholder: 'Choose a location'
                    },
                },
            },
            blacklisted: {
                label: 'Blacklisted',
                options: {
                    all: 'All',
                    blacklisted: 'Yes',
                    notBlacklisted: 'Not',
                }
            },
        },
        contact: {
            field: {
                addContact: {
                    label: 'Add contact'
                },
                generalContact: {
                    label: 'General contact'
                },
                documentsInvoices: {
                    label: 'Invoice'
                },
                separatePOD: {
                    label: 'Separate POD (only for personalizing option "Invoice and POD to be sent separately to different e-mails")'
                },
                customerUpdate: {
                    label: 'Customer Update'
                },
                customerCustoms: {
                    label: 'Customer Customs'
                },
                generalName: {
                    name: 'General name',
                    email: 'General "To" email address',

                },
                documentsInvoicesName: {
                    name: 'Documents & Invoices "To" name',
                    email: 'Doc. & Invoices "To" e-mail address',
                },
                separatePODName: {
                    name: 'Separate POD "To" name',
                    email: 'Separate POD "To" e-mail address',
                },
                customerUpdateName: {
                    name: 'Customer Update "To" name',
                    email: 'Cust. Update "To" email address',
                },
                customerCustomName: {
                    name: 'Customer Customs "To" name',
                    email: 'Cust. Customs "To" email address',
                },
            },
            title: 'Email configurations'
        },
        order: {
            overview: {
                customerName: 'Cust. name',
                customerReference: 'Cust. ref.',
                loadingPlace: 'Loading Place',
                loadingDate: 'Loading date',
                unloadingPlace: 'Unloading place',
                unloadingDate: 'Unloading date',
                statusIn: 'Status in:',
            },
            statuses: {
                new: 'New',
                quoted: 'Quoted',
                accepted: 'Accepted',
                rejected: 'Rejected',
                planned: 'Planned',
                completed: 'Completed',
            },
            create: {
                title: 'Add Customer',
            },
            edit: {
                title: 'Edit Customer',
            },
            delete: {
                title: 'Delete Customer',
            },
            field: {
                name: {
                    label: 'Name',
                    placeholder: 'Fill in a name',
                },
                id: {
                    label: 'Order no.',
                },
                customer: {
                    label: 'Customer',
                },
                salesPrice: {
                    label: 'Price',
                },
                reference: {
                    label: 'Reference',
                },
                created_at: {
                    label: 'Created at',
                },
                updated_at: {
                    label: 'Updated at',
                },
                status: {
                    label: 'Status',
                }
            },
        },
        tab: {
            contact: 'Contacts',
            locations: 'Address book',
            routes: 'Routes & pricing'
        }
    },
    sales: {
        uiFilter: {
            removeLastSalesLine: 'The sales plan need to exist and have no trips assigned to it.',
            filtersNotDefined: 'Filters need to be defined. Select date and district.',
        }
    },
    planning: {
        na: 'NA',
        showAll: 'Show All',
        noItems: 'No items available',
        allTrips: 'All trips',
        trips: 'Trips',
        yards: 'Yards',
        service: 'Service',
        not_booked: 'Not booked',
        title: {
            sales: 'Sales',
            forwarding: 'Forwarding',
            planning: 'Planning',
            truckPlanning: 'Truck Planning',
            trailerPlanning: 'Trailer Planning',
            salesPlanning: 'Sales Planning',
        },
        generateSalesPlan: 'Generate',
        activityChangedWarning: {
            note: {
                newPlanTripCanBePlanned: 'Note that by clicking ‘Remove warning’, the available trailer will be removed from the old date\'s overview and the order that was planned on the old date will move back to the ‘open orders’.',
                newPlanTripCanNotBePlanned: 'Note that after clicking ‘Remove warning‘, the available trailer and the planned activity will stay in the current overview since the planned order is already in progress.',
                newPlanNoTrip: 'Note that by clicking ‘Remove warning’, the empty trailer will be removed from the old date\'s overview.',
                oldPlanTrip: 'Note that the available trailer is moved to the new date\'s overview and that by clicking ‘Remove warning’, the planned order will move back to the ‘open orders’.',
                oldPlanNoTrip: 'Note that the empty trailer is moved to the new date\'s overview.',
                defaultMessage: 'Note that by clicking ‘Remove warning’, the trailer will disappear from the overview.',
            },
            title: {
                changedFrom: 'Changed from:',
                changedTo: 'Changed to:',
                removeWarning: 'Remove warning',
                locationChangedPrefix: 'The location of the ',
                dateChangedPrefix: 'The date of ',
                activityAddedPrefix: 'A new ',
                lastIndexDescription: 'last',
                activityTypeDescription: '{{indexDescription}} {{activityType}} activity',
                activityChangedSuffix: ' changed!',
                activityAddedSuffix: ' has been added!',
                activityExecutingMessage: 'This order is not planned anymore',
                activityCanceledMessage: 'This activity is canceled',
                orderExceptions: 'Order Exceptions!'
            },
            exceptionInfo: {
                amountOfUnloads: 'Amount of unloads',
                lateUnloads: 'Unloads after 09:00',
                loadsOfNumber: '{{late}} out of {{total}}',
            }
        },
        filters: {
            tab: {
                planningShort: 'Planning',
                planning: 'Planned',
                trips: 'Open trips',
                tripsShort: 'Trips',
                orders: 'Open orders',
                ordersShort: 'Orders'
            },
            search: {
                label: 'Search',
                placeholder: 'Any location within orders',
            },
            job: {
                label: 'Job',
                all: 'All',
                load: 'Load',
                unload: 'Unload',
            },
            geopoint: {
                label: 'Geopoint location',
                placeholder: 'Location',
            },
            radius: {
                label: 'Radius',
                placeholder: 'KM',
            },
            fleetNumber: {
                label: 'Fleet Number',
                placeholder: 'Truck / Trailer',
            },
            districts: {
                label: 'Districts',
                placeholder: 'Select district',
            },
            date: {
                label: 'Date',
                placeholderStart: 'Start date',
                placeholderEnd: 'End date',
            },
            clearButton: {
                label: 'Clear filters'
            },
            section: {
                label: 'Section'
            },
            planning: {
                label: 'Planning'
            },
            status: {
                label: 'Trip status',
            },
            openTrips: {
                label: 'Open trips'
            },
            collapsed: {
                label: 'Collapse',
            },
            showFinished: {
                label: 'Show finished',
            },
            hideColumns: {
                label: 'Hide columns',
            },
            addLine: {
                label: 'Add line',
            },
            removeLine: {
                label: 'Remove line',
            },
            inactive: {
                label: 'Active / Inactive',
                all: 'All',
                active: 'Active ',
                inactive: 'Inactive',
            },
            assignedTo: {
                label: 'Assigned to',
            },
            owner: {
                label: 'Owner',
            },
            all: {
                label: 'All',
            },
            waiting: {
                label: 'Waiting',
            },
            busy: {
                label: 'Busy',
            },
            limit: {
                label: 'Limit',
            },
            autoRegenerate: {
                label: 'Each 5-minute regenerates sales plan'
            },
            flaggedRows:{
                label: 'Flagged rows',
                all: 'All',
                flagged: 'Flagged',
                flagAllRows: {
                    buttonLabel: 'Toggle Flags',
                    notification: 'All filtered rows has been flagged'
                }
            },
            weekendPause:{
                label: 'Weekend pause'
            }
        },
        header: {
            trailer: 'Trailer no.',
            truck: 'Truck no.',
            fixedNotes: 'Fixed remarks',
            ferryStatusUnloadLocaction: 'Ferry status & last unloading location',
            unloadLocation: 'Last unloading location',
            eta: 'ETA',
            emptyKm: 'Empty km',
            chat: 'Chat',
        }
    },
    capacity: {
        header: {
            attention: 'Attention',
            openTrips: 'Open trips',
            openOrders: 'Open orders',
            plannedOrders: 'Planned orders',
        },
        filters: {
            clearButton: {
                label: 'Clear filters',
            }
        },
        fields: {
            unknown: {
                label: 'Unknown',
            },
            remarks: {
                label: '-- no remarks --'
            },
        },
        etaSorting: {
            header: 'Sort by',
            default: 'Default',
            ascending: 'ETA (ascending)',
            descending: 'ETA (descending)',
        }
    },
    trailer: {
        create: {
            title: 'Add trailer'
        },
        overview: {
            title: 'Trailer overview',
            addButton: 'Add trailer',
        },
        edit: {
            title: 'Edit trailer {{id}}',
            basicInfoTitle: 'Basic info',
            trailerSpecTitle: 'Trailer specs',
            apkTitle: 'TUV',
            activate: 'Activate trailer',
            deactivate: 'Deactivate trailer',
            cannotDeactivate: 'Cannot deactivate. Please send trailer to yard first',
            deactivated: 'The trailer is deactivated',
            locationRequired: 'Fill the yard location which is required',
            dropTruck: 'Drop the truck to deactivate trailer',
        },
        field: {
            attachedTruck: {
                label: 'Attached truck',
            },
            id: {
                label: 'Id',
            },
            licensePlate: {
                label: 'License plate',
            },
            apkExpiryDate: {
                label: 'TUV expiry date'
            },
            apkExpiryDateFrom: {
                label: 'TUV expiry date from'
            },
            apkLastExecutedDate: {
                label: 'TUV last executed date'
            },
            apkExecutedDate: {
                label: 'TUV executed date'
            },
            apkExpiryDateNew: {
                label: 'TUV expiry date new'
            },
            fleetNumber: {
                label: 'Fleet number'
            },
            fleetCode: {
                label: 'Fleet code'
            },
            service: {
                label: 'Service'
            },
            owner: {
                label: 'Owner'
            },
            truckingCompany: {
                label: 'Owner'
            },
            dataSource: {
                label: 'Data provider',
            },
            dataReference: {
                label: 'Transics key',
            },
            location: {
                label: 'Yard location',
            },
            source: {
                label: 'source'
            },
            equipment: {
                label: 'Equipment'
            },
            remarks: {
                label: 'Remarks'
            },
            active: {
                label: 'Active / Inactive trailer',
                overviewColumn: 'Active',
                options: {
                    all: 'All',
                    yes: 'Active',
                    no: 'Inactive',
                }
            },
            assignedUser: {
                label: 'Assigned to'
            },
        }
    },

    truck: {
        overview: {
            title: 'Truck overview',
            addButton: 'Add truck',
        },
        modal: {
            headerTitle: 'Fill the location to activated truck',
            headerTitleSplitTrip: 'Fill the location to yard drop - pick up',
            changeActivityType: 'Change type Activity to yard drop',
            detachTrailerFromTruck: 'Detach trailer from the truck first',
            changeModalEta: 'Change manual ETA',
        },
        edit: {
            title: 'Edit truck {{id}}',
            basicInfoTitle: 'Basic info',
            identificationTitle: 'Truck identification',
            specsTitle: 'Truck specs',
            driverTitle: 'Driver',
            serviceAndMaintenance: 'Service and maintenance',
            activate: {
                label: 'Activate truck',
                success: 'Truck successfully activated',
            },
            deactivate: {
                label: 'Deactivate truck',
                success: 'Truck successfully deactivated',
            },
            deactivated: 'The truck is deactivated',
            locationTruck: 'Fill the yard location which is required',
            dropTrailer: 'Drop the trailer to deactivated truck',
        },
        field: {
            id: {
                label: 'Id',
            },
            attachedTrailer: {
                label: 'Attached trailer',
            },
            fleetNumber: {
                label: 'Fleet number',
            },
            licensePlate: {
                label: 'License plate',
            },
            owner: {
                label: 'Owner',
            },
            contract: {
                label: 'Contract',
            },
            truckingCompany: {
                label: 'Owner company'
            },
            telematica: {
                label: 'Data Provider',
            },
            dataSource: {
                label: 'Data provider',
            },
            dataReference: {
                label: 'Transics key',
            },
            vinNumber: {
                label: 'VIN number',
            },
            phone: {
                label: 'Phone',
            },
            location: {
                label: 'Yard location',
            },
            terminal: {
                label: 'Supported Terminals',
            },
            color: {
                label: 'Color'
            },
            type: {
                label: 'Type',
                value: {
                    dallessi: 'Dalessi',
                    TIP: 'TIP'
                }
            },
            registrationCountry: {
                label: 'Registration country'
            },
            brandType: {
                label: 'Brand type'
            },
            emailHaulier: {
                label: 'Email haulier'
            },
            service: {
                label: 'Service'
            },
            weight: {
                label: 'Weight'
            },
            registrationDate: {
                label: 'Registration date'
            },
            fixedEquipment: {
                label: 'Fixed equipment'
            },
            remarks: {
                label: 'Remarks'
            },
            entity: {
                label: 'Entity'
            },
            active: {
                label: 'Active / Inactive truck',
                overviewColumn: 'Active',
                options: {
                    all: 'All',
                    yes: 'Active',
                    no: 'Inactive',
                }
            },
            assignedUser: {
                label: 'Assigned to'
            },
            currentLocation: {
                label: 'Current Location'
            },
            currentActivity: {
                label: 'Current Activity'
            },
            manualEta: {
                label: 'Manual ETA'
            },
            weekendPause: {
                overviewColumn: 'Weekend pause',
            },
            driver: {
                field: {
                    name: {
                        label: 'Name'
                    },
                    phoneNumber: {
                        label: 'Phone'
                    },
                    weekendPause: {
                        label: 'Weekend pause'
                    }
                }
            },
        },
        create: {
            title: 'Add truck'
        },
    },
    tuv: {
        overview: {
            addButton: 'Add TUV',
        },
        field: {
            tuvExecutedDate: {
                label: 'TUV executed date'
            },
            tuvExpiryDate: {
                label: 'TUV expiry date'
            },
        }
    },
    truckingCompanyContract: {
        overview: {
            title: 'Trucking contracts overview',
        },
        create: {
            title: 'Add trucking contract',
        },
        edit: {
            title: 'Edit trucking contract',
        },
        field: {
            id: {
                label: 'Id'
            },
            name: {
                label: 'Name'
            },
            truckingCompany: {
                label: 'Trucking Company'
            },
        },
        contact: {
            field: {
                addContact: {
                    label: 'Add contact'
                },
                generalContact: {
                    label: 'General contact'
                },
                documentsInvoices: {
                    label: 'Documents & Invoices'
                },
                planningDispatchContact: {
                    label: 'Planning / Dispatch'
                },
                generalName: {
                    name: 'General name',
                    email: 'General "To" email address',
                },
                documentsInvoicesName: {
                    name: 'Documents & Invoices "To" name',
                    email: 'Doc. & Invoices "To" e-mail address',
                },
                planningDispatchContactName: {
                    name: 'Planning / Dispatch "To" name',
                    email: 'Planning / Dispatch "To" email address',
                },
            },
        },
    },
    truckingCompanyTariff: {
        latest: 'latest',
        field: {
            id: {
                label: 'Id'
            },
            name: {
                label: 'Name'
            },
            startDate: {
                label: 'Start date'
            },
            endDate: {
                label: 'End date'
            },
            applyRoadPricing: {
                label: 'Apply Road Pricing?'
            },
            kmRates: {
                label: 'Km rates'
            }
        },
        button: {
            add: 'Add tariff',
            delete: 'Delete tariff',
        }
    },
    truckingCompanyTariffKmRate: {
        plural: 'Km rates',
        field: {
            minKm: {
                label: 'Min. Km'
            },
            maxKm: {
                label: 'Max. Km'
            },
            price: {
                label: 'Price'
            },
            fuelSurchargeFactor: {
                label: 'Fuel surcharge factor'
            },
        },
        button: {
            add: 'Add Km rate',
        }
    },
    driver: {
        overview: {
            title: 'Drivers truck list',
        },
        create: {
            title: 'Add driver',
        },
        edit: {
            title: 'Edit driver',
        },
        field: {
            header: {
                label: 'Driver'
            },
            id: {
                label: 'Id'
            },
            name: {
                label: 'Driver\'s name',
                placeholder: 'Driver\'s name',
            },
            phoneNumber: {
                label: 'Driver\'s phone',
                placeholder: 'Driver\'s phone number',
            },
            addDriver: {
                label: 'Add driver'
            },
            selectDriver: {
                label: 'Select existing driver'
            },
            truck: {
                label: 'Truck',
                licensePlate: {
                    label: 'Truck license plate'
                },
                fleetNumber: {
                    label: 'Truck fleet number'
                },
            },
            weekendPause:{
                label: 'Weekend pause',
                options:{
                    H24: '24h',
                    H45: '45h',
                    HOME: 'Home',
                    STOP_TRUCK: 'Stop truck'
                },
                placeholder: 'Unknown',
            },
            authentication: {
                label: 'Authentication'
            },
            assignedDriver: {
                label: ' (Driver: {{name}})',
                empty: 'none'
            }
        },
        tachoStatistics: {
            workingDay: {
                label: 'Working day',
            },
            lastExternalActivity: {
                label: 'Duration',
            },
            dayStart: {
                label: 'Start',
            },
            dayEnd: {
                label: 'Finish',
            },
            drivingTimeLabel: 'Driving Time',
            dayDrivingTime: {
                label: 'Done',
            },
            remainingDayDrivingTime: {
                label: 'TO-DO (DAY)',
            },
            remainingDayWorkingTime: {
                label: 'TO-DO',
                none: 'None',
            },
            canWork15Hours: {
                label: 'Extended (15h/9h)',
                options: {
                    true: 'Yes, {{times}}x',
                    false: 'No',
                    '2nddriver': 'Two drivers',
                },
            },
            canDriveExtended: {
                label: {
                    10: 'Extended (10h)',
                    20: 'Extended (20h)',
                },
                options: {
                    true: 'Yes, {{times}}x',
                    false: 'No',
                },
            },
            workingTime: {
                label: 'Done',
            },
            break: {
                label: 'Next Break',
            },
            weekendBreak: {
                label: 'Weekend Break',
            },
            weekendBreakHours: {
                label: 'Length',
            },
            weekendBreakStart: {
                label: 'Start',
            },
            nextBreakHours: {
                label: 'Length',
            },
            nextBreakStart: {
                label: 'Start',
            },
            operationalWeekStartedAt: {
                label: 'Operational Week Started',
            },
            remainingWeekDrivingTime: {
                label: 'TO-DO (week)',
            },
            tachoLabel: 'Tacho',
            statusLabel: 'Status',
            lastStatusUpdateLabel: 'Last status update',
            locationLabel: 'Location (measured at: {{measuredAt}})',
            informationNone: 'Tacho statistics are not available'
        },
        tachoEvent: {
            action: {
                label: 'Action',
                value: {
                    none: 'None',
                    drive: 'Drive',
                    work: 'Work',
                    wait: 'Wait',
                    rest: 'Rest',
                    availability: 'Availability',
                    not_available: 'Not available',
                    undefined: '',
                },
            },
        }
    },
    goodsDescription: {
        overview: {
            title: 'Goods description list',
        },
        create: {
            title: 'New goods description',
        },
        edit: {
            title: 'Edit goods description',
        },
        field: {
            id: {
                label: 'Id'
            },
            name: {
                label: 'Description',
                placeholder: 'Description',
            },
            usageCount: {
                label: 'Frequency of use',
                placeholder: 'Frequency of use',
            }
        }
    },
    service: {
        overview: {
            title: 'Service and maintenance overview',
            button: {
                add: 'Add service/maintenance',
            },
        },
        list: {
            button: {
                add: 'Add service',
                showMore: 'Show more',
            }
        },
        create: {
            title: 'Add service/maintenance item',
        },
        edit: {
            title: 'Edit service/maintenance {{id}}',
            button: {
                setAsExecuted: 'Set as executed',
                uploadDocuments: 'Upload general documents',
                uploadInvoices: 'Upload invoices',
                saveAndCreateActivity: 'Save and create activity',
            }
        },
        delete: {
            title: 'Delete service',
        },
        field: {
            id: {
                label: 'Id',
            },
            status: { label: 'Status' },
            type: {
                label: 'Type',
            },
            location: {
                label: 'Location',
                placeholder: 'Input'
            },
            subject: {
                label: 'Subject',
                placeholder: 'Input'
            },
            explanation: {
                label: 'Explanation',
                placeholder: 'Input'
            },
            truck: {
                label: 'Truck fleet number',
                licensePlate: {
                    label: 'Truck license plate',
                    placeholder: 'Input'
                },
                placeholder: 'Input'
            },
            trailer: {
                label: 'Trailer fleet number',
                licensePlate: {
                    label: 'Trailer license plate',
                    placeholder: 'Input'
                },
                placeholder: 'Input'
            },
            serviceSubtype: {
                label: 'Subtype',
                placeholder: 'Input'
            },
            plannedAt: {
                label: 'Planned date & time',
                date: 'Planned date',
                placeholder: 'Input'
            },
            estimatedDue: {
                label: 'Estimated due date & time',
                placeholder: 'Input'
            },
            estimatedDuration: {
                label: 'Estimated duration',
                placeholder: 'X hours',
                hours: '{{hours}} hours'
            },
            executedAt: {
                label: 'Executed date',
                placeholder: 'Input',
                set: 'Set executed at',
                modal: {
                    noPlannedAtDate: {
                        header: 'Warning',
                        content: 'With this action you will set this {{service}} as executed, although no planned date was added. Are you sure you want to continue?',
                    },
                },
            },
            expiredAt: {
                label: 'Expiry date'
            },
            asset: {
                label: 'Asset type'
            },
            assetFleetNumber: {
                label: 'Asset ID'
            },
            documents: {
                label: 'Documents'
            },
            invoices: {
                label: 'Invoices'
            },
            tasks: {
                label: 'Related tasks'
            },
            linkedActivity: {
                label: 'Linked activity'
            }
        },
        button: {
            createServiceActivity: {
                label: 'Create service activity',
                tooltip: {
                    enabled: 'Creating a service sctivity will make it available to plan in the `open trips`',
                    disabled: 'You can create a service activity when the `planned at` date is filled in'
                }
            }
        },
        bulk: {
            bulkAction: 'Bulk action',
            setExecutedAtOtherDate: 'Set \'executed at\' at other date',
            setExecutedAtToday: 'Set \'executed at\' today\'s date',
            modal: {
                header: 'Bulk set executed at'
            }
        }
    },
    route: {
        overview: {
            title: 'Schedules overview',
            addButton: 'Add schedule',
            editButton: 'Edit schedule',
            copyButton: 'Copy schedule',
        },
        create: {
            title: 'New Schedule',
        },
        view: {
            title: 'Schedule view',
        },
        delete: {
            title: 'Delete schedule',
        },
        schedules: {
            status: 'Status',
            addDeparture: 'New Departure',
            editDeparture: 'Edit Departure',
            weekday: 'Weekday',
            cutOffTime: 'Cut-off Time',
            departureTime: 'Departure Time',
            arrivalTime: 'Arrival Time',
            releaseTime: 'Release Time',
            fullUnitPrice: 'Full Unit Price',
            emptyUnitPrice: 'Empty Unit Price',
            bafSurcharge: 'BAF Surcharge',
            extraSurcharge: 'Extra Surcharge',
            validFrom: 'Valid from',
            validTo: 'Valid to',
            view: 'View schedule',
            edit: 'Edit schedule',
            copy: 'Copy schedule',
            deactivate: 'Deactivate schedule including all of its departures',
            delete: 'Delete schedule including all of its departures',
            createNote: 'Note that you first need to create at least two terminals for this terminal transporter, before schedules can be made',
        },
        field: {
            name: {
                label: 'Name',
            },
            code: {
                label: 'Integration Code',
            },
            transporter: {
                label: 'Terminal Transporter',
            },
            fromTerminal: {
                label: 'Departure Terminal',
            },
            toTerminal: {
                label: 'Arrival Terminal',
            },
            invoiceWay: {
                label: 'Invoice Way',
            },
            schedules: {
                label: 'Schedules',
            },
            deactivateModal:{
                description: 'Please enter the date from which this schedule and its departures should be deactivated.'
            }
        }
    },
    transporter: {
        overview: {
            title: 'Transporter overview',
            addButton: 'Add transporter',
        },
        create: {
            title: 'Create Transporter',
        },
        edit: {
            title: 'Transporter edit',
        },
        field: {
            name: {
                label: 'Name',
                placeholder: 'Fill in a name',
            },
        }
    },
    location: {
        field: {
            city: {
                label: 'City',
            },
            code: {
                label: 'Code',
            }
        },
    },
    terminalTransporter: {
        overview: {
            title: 'Terminal Transporters overview',
            addButton: 'Add transporter',
            deactivateButton: 'Deactivate schedule and all of its departures'
        },
        create: {
            title: 'Create Terminal Transporter',
        },
        edit: {
            title: 'Terminal Transporter - {{name}}',
        },
        field: {
            defaultCurrency: {
                label: 'Default currency for purchase invoices',
                value: {
                    euro: 'EUR',
                    pound: 'GBP',
                },
                code: {
                    euro: '€',
                    pound: '£',
                }
            },
            name: {
                label: 'Name',
                placeholder: 'Fill in a name',
            },
            creditorNumber: {
                label: 'Creditor number',
                placeholder: 'Fill in a creditor number',
            },
            emailAddress: {
                label: 'Email Address',
                placeholder: 'e-mail',
            },
            phone: {
                label: 'Telephone',
                placeholder: 'phone number',
            },
            address: {
                label: 'Street Address',
                placeholder: 'Street, City',
            },
            zipCode: {
                label: 'Postal Code',
                placeholder: 'Code',
            },
            country: {
                label: 'Country',
                placeholder: 'Select country',
            },
            iban: {
                label: 'IBAN',
                placeholder: 'Fill in a IBAN number',
            },
            swiftBic: {
                label: 'SWIFT / BIC code',
                placeholder: '',
            },
            chamberOfCommerce: {
                label: 'Chamber of Commerce',
                placeholder: '',
            },
            vatCode: {
                label: 'VAT code',
                placeholder: '',
            },
            contact: {
                label: 'Contact',
                placeholder: 'Select contact type',
                value: {
                    dayshift: 'Dayshift',
                    invoice: 'Invoice',
                    nightshift: 'Nightshift',
                    administration: 'Administration',
                    general: 'General',
                    operation: 'Operation'
                }
            },
            contract: {
                label: 'Contract',
            },
            contractExpiryDate: {
                label: 'Contract expiry date',
            },
            termsAndAgreements: {
                label: 'Terms and Agreements',
            },
            terminals: {
                label: 'Terminals',
            }
        },
        tab: {
            schedules: 'Schedules',
            terminals: 'Terminals',
        },
        terminal: {
            add: 'Add Terminal',
            name: 'Name',
            address: 'Address',
            country: 'Country',
            geoLocation: 'Geo location',
            emailAddress: 'Email address',
            phoneNumber: 'Telephone',
        },
        schedules: {
            add: 'Add Schedule',
            departureTerminal: 'Dept. Terminal',
            arrivalTerminal: 'Arr. Terminal',
            weekdays: 'Weekdays',
            lastUpdate: 'Last Update',
            invoiceWay: 'Invoice Way',

        }
    },
    terminal: {
        overview: {
            title: 'Terminal overview',
            addButton: 'Add terminal',
        },
        create: {
            title: 'Create Terminal',
        },
        edit: {
            title: 'Edit terminal {{id}}',
        },
        field: {
            id: {
                label: 'Id',
                placeholder: 'Id',
            },
            name: {
                label: 'Terminal Name',
                placeholder: 'Name',
            },
            transporter: {
                label: 'Transporter Name',
                placeholder: 'Transporter',
            },
            emailAddress: {
                label: 'Email Address',
                placeholder: 'Email',
            },
            phoneNumber: {
                label: 'Telephone',
                placeholder: 'Telephone',
            },
            address: {
                label: 'Address',
                placeholder: 'Address',
            },
            street: {
                label: 'Street',
                placeholder: 'Street',
            },
            city: {
                label: 'City',
                placeholder: 'City',
            },
            country: {
                label: 'Country',
                placeholder: 'Country',
            },
            geoLocation: {
                label: 'Geo Location',
                placeholder: 'Location',
            },
            costArea: {
                label: 'Cost area',
                placeholder: 'Cost area',
            },
        }
    },
    schedule: {
        overview: {
            title: 'Schedule overview',
            addButton: 'Add departure',
            editButton: 'Edit departure',
            copyButton: 'Copy departure',
            deactivateButton: 'Deactivate departure',
        },
        create: {
            title: 'Create Schedule',
        },
        edit: {
            title: 'Schedule edit',
            departureSectionHeader: 'Departure information',
            costsSectionHeader: 'Costs information',
            periodSectionHeader: 'Period in which departure is valid',
            validToWarning: 'WARNING. There already exist a departure for (a part of) this period with the same departure day and departure and arrival time',
            validToRemark: 'Note that the \'valid to\' date of schedule will be automatically filled in by the system when a new schedule is added with the same departure information',
            equalTerminals: 'Selected departure terminal is equal to arrival terminal',
            terminalCombinationExist: 'Selected combination of terminals already has current (active) configuration',
        },
        field: {
            name: {
                label: 'Name',
            },
            transporter: {
                label: 'Transporter',
            },
            route: {
                label: 'Route',
            },
            boardingAt: {
                label: 'Boarding at',
            },
            departure: {
                label: 'Departure',
            },
            arrival: {
                label: 'Arrival',
            },
            disembarkAt: {
                label: 'Disembark at',
            },
        }
    },
    booking: {
        buttons: {
            book: 'Create Booking',
            bookModify: 'Change Booking'
        },
        overview: {
            title: 'Ferry bookings overview',
            addButton: 'Add booking',
            popups: {
                exclamation: 'This booking is already included in another purchase invoice',
                warningActualArrival: 'The booked and actual arrival time differ more than 3 hours',
            },
            departure: {
                label: 'Booked departure'
            },
            departureActual: {
                label: 'Actual departure'
            },
            arrival: {
                label: 'Booked arrival'
            },
            arrivalActual: {
                label: 'Actual arrival'
            },
        },
        edit: {
            title: 'Edit booking {{id}} (ref: {{ref}}, booking date: {{booking_date}}, created at: {{created_at}})',
            generalInformation: 'General Information',
            request: 'Request data',
            response: 'Response',
            additionalInformation: 'Additional Information',
            invoiceInformation: 'Invoice Information',
            segments: {
                cargo_details: 'Cargo details',
                map_trip_details: 'Trip details',
                cargo_lines: 'Cargo lines',
                booking_history: 'Booking history',
            },
        },
        field: {
            id: {
                label: 'Booking Id'
            },
            bookingReference: {
                label: 'Booking Reference',
                placeholder: 'Input provided here'
            },
            status: {
                label: 'Status',
            },
            purchaseInvoiceStatus: {
                label: 'Purchase invoice status'
            },
            purchaseInvoiceAmount: {
                label: 'Purchase invoice amount'
            },
            from: {
                label: 'From'
            },
            to: {
                label: 'To'
            },
            departure: {
                label: 'Departure'
            },
            departureActual: {
                label: 'Departure'
            },
            arrival: {
                label: 'Arrival'
            },
            arrivalActual: {
                label: 'Arrival'
            },
            release: {
                label: 'Release'
            },
            licensePlate: {
                label: 'License Plate'
            },
            cargoSpecification: {
                label: 'Cargo Specification'
            },
            unNumber: {
                label: 'Un Number'
            },
            classNumber: {
                label: 'Class (1-9)'
            },
            packageGroup: {
                label: 'Packgroup (1-3)'
            },
            lq: {
                label: 'LQ'
            },
            mrnNumber: {
                label: 'Mrn Number'
            },
            additionalInformation: {
                label: 'Additional Information',
                placeholder: 'Remarks'
            },
            cargoDescription: {
                label: 'Cargo Specification',
                placeholder: 'Cargo Specification'
            },
            invoiceStatus: {
                label: 'Invoice Status'
            },
            purchasePrice: {
                label: 'Booked purchase price'
            },
            invoiceAmount: {
                label: 'Invoice Amount'
            },
            bookingDate: {
                label: 'Date'
            },
            invoiceAmountDifference: {
                label: 'Difference'
            },
            integrationStatus: {
                label: 'Integration status',
            },
            weight: {
                label: 'Weight',
                placeholder: 'Total weight in KG',
            },
            packagingAmount: {
                label: 'Amount',
            },
            packagingType: {
                label: 'Packaging Type',
            },
            trailerEmpty: {
                label: 'Empty trailer?',
            },
            trailerFleetNumber: {
                label: 'Trailer fleet number',
                placeholder: 'Trailer Number',
            },
            ucrNumber: {
                label: 'UCR number',
            },
            communityCode: {
                label: 'Community code',
            },
            shippingReference: {
                label: 'Shipping reference',
            },
            pinCode: {
                label: 'Pin code',
            },
            goodsDescription: {
                label: 'Goods description'
            },
            adr:{
                label: 'ADR'
            },
            gmrNumber: {
                label: 'GMR number',
            },
        },
        modal: {
            modalConfirmation: {
                title: 'Booking confirmation',
                description: 'The following bookings has already been included in another purchase invoice: bookingId {{booking_id}}, purchaseInvoiceId {{purchase_invoice_id}}. \n Are you sure you want to continue?',
            }
        }
    },
    ferryModal: {
        cargoDetails: 'Cargo details',
        possibleSailings: 'Possible sailings',
        showAll: 'Show all',
        hideAll: 'Hide all',
        calculateCheapest: 'Calculate cheapest',
        map: 'Map',
        bookNow: 'Book now',
        tripCostDescription: 'Choose a sailing below to see its cost estimation',
        tripDetails: {
            title: 'Trip details',
            route: 'Ferry booking:',
            collection: 'Collection:',
            ferryFrom: 'Ferry from:',
            distance: 'Distance:',
            ferryCosts: 'Ferry costs:',
            costEstimation: 'cost estimation',
            ferryTo: 'Ferry to:',
            delivery: 'Delivery:',
            totalPrice: 'Total price:',
            kilometers: 'Kilometers',
            miles: 'miles',
            gbpeur: 'GBP / EUR',
        },
        tooltipFerry: {
            cheapest: 'Cheapest ferry',
            cheapest2: '2nd cheapest ferry',
            others: 'Others',
        }
    },
    purchaseInvoice: {
        overview: {
            title: 'Purchase Invoice Overview',
            addButton: 'Add Purchase Invoice',
            subcontractor: 'Subcontractor',
            terminalTransporter: 'Term. Transporter',
            selfBillCompany: 'Self bill',
            accept: 'Accept',
            reject: 'Reject',
            netAmountFrom: 'Net amount from',
            netAmountTo: 'Net amount to',
            licensePlate: 'License plate',
            fleetNumber: 'Truck fleet no.',
            weekNr: 'Week no.',
            yearNr: 'Year no.',
            invoiceNumber: 'Invoice number terminal transporter',
            popups: {
                reworked: 'Revised: purchase invoice was rejected after which it was revised',
                priceDifference: 'Flagged because there is a difference between purchase price and invoiced amount',
                correction: 'Flagged because a correction was applied',
                purchasePriceAndAmountDiff: 'Flagged because there is a difference between purchase price and net amount'
            }
        },
        modalConfirmation: {
            title: 'Confirm',
            description: 'The default currency for {{terminalTransporterName}} is {{terminalTransporterCurrency}}. Are you sure you want to save this purchase invoice in {{invoiceCurrency}}?',
        },
        create: {
            title: 'Create Purchase Invoice',
        },
        delete: {
            title: 'Mark as archived',
            cannotArchive: 'You can only archive purchase invoices with status "draft"'
        },
        edit: {
            title: 'Edit purchase invoice {{id}}',
            segments: {
                lines: 'Lines',
            }
        },
        reject: {
            title: 'Reject purchase invoice'
        },
        accept: {
            title: 'Accept purchase invoice'
        },
        rejectShort: {
            title: 'Reject'
        },
        acceptShort: {
            title: 'Accept'
        },
        download: {
            title: 'Download most recently send purchase invoice PDF',
        },
        disabledDownload: {
            title: 'No purchase invoice PDF is send yet',
        },
        field: {
            id: {
                label: 'Self bill',
            },
            invoiceDate: {
                label: 'Invoice Date',
            },
            internalRemarks: {
                label: 'Internal Remarks',
            },
            grossAmount: {
                label: 'Gross Amount',
            },
            formattedGrossAmount: {
                label: 'Gross Amount',
            },
            netAmount: {
                label: 'Net Amount',
            },
            formattedNetAmount: {
                label: 'Net Amount',
            },
            type: {
                label: 'Type',
                value: {
                    'all': 'All',
                    'other': 'Other',
                    'self_bill': 'Self Bill',
                    'terminal_transporter': 'Terminal Transporter',
                    'subcontract': 'Subcontract',
                },
            },
            status: {
                label: 'Status',
                value: {
                    'draft': 'Draft',
                    'pending': 'Pending',
                    'accepted': 'Accepted',
                    'rejected': 'Rejected',
                    'archived': 'Archived',
                },
            },
            currency: {
                label: 'Currency',
                value: {
                    'euro': 'Euro',
                    'pound': 'Pound',
                },
            },
            documents: {
                label: 'Documents',
            },
            createdAt: {
                label: 'Created at',
            },
            vat: {
                label: 'VAT',
            },
            salesPrice: {
                label: 'Sales price',
            },
            priceDifference: {
                label: 'Difference',
            },
            invoiceAmount: {
                label: 'Invoice amount',
            },
            purchasePrice: {
                label: 'Purchase price',
            },
            purchasePriceFrom: {
                label: 'Purchase price from (in cents)',
            },
            purchasePriceTo: {
                label: 'Purchase price to (in cents)',
            },
            correction: {
                label: 'Correction',
            },
            correctionTotal: {
                label: 'Correction',
            },
            dailyRateCorrection: {
                label: 'Daily rate correction'
            },
            other: {
                label: 'Other'
            },
            booking: {
                label: 'Booking Id'
            },
            anomalityFlagging: {
                label: 'Anomalies',
                onlyFlagged: 'Only flagged',
                onlyReworked: 'Only reworked',
                all: 'All anomalies'
            },
            sentAt: {
                label: 'Send date'
            },
            draftSentAt: {
                label: 'Send date'
            },
            reasonOfRejection: {
                label: 'Reason of rejection'
            },
        },
        rejectionModal: {
            description: 'Rejection reason',
            title: ' Reject self bill',
            requiredFieldLabel: 'This field is required'
        },
        summaryContainer:{
            label: 'Purchase invoice summary'
        },
        downloadSendBillButton:{
            label: 'Download most recently send self bill'
        }

    },
    purchaseInvoiceDocument: {
        field: {
            id: {
                label: 'Id',
            },
            description: {
                label: 'Description',
            },
        },
    },
    purchaseInvoiceSelfBill: {
        showFindTripActivitiesModalButton: 'Find Trip Activities',
        calculatePurchasePrice: 'Calculate purchase price',
        calculateRoadPricing: 'Calculate road pricing',
        downloadPdfButton: 'Download PDF',
        errorNotification: {
            message: 'You cannot add trip activity to \'archived\' purchase invoice',
        },
        field: {
            id: {
                label: 'Id',
            },
            truckingCompany: {
                label: 'Trucking Company',
            },
            tripActivityLines: {
                label: 'Trip Activity Lines',
            },
            purchasePrice: {
                label: 'Purchase price',
            },
            amount: {
                label: 'Invoice amount',
            },
            selfBillDate: {
                label: 'Self Bill Date',
                errorMultipleWeeks: 'Activities span across multiple weeks'
            },
            notInvoiceable: {
                label: 'Show trip activities with status \'not invoiceable\'',
                options: {
                    yes: 'Yes',
                    no: 'No',
                }
            }
        },
        truckSelectionTable: {
            truck: {
                fleetNumber: 'Truck fleet no.',
                licensePlate: 'Truck license plate',
                id: 'Truck Id',
            }
        },
        tripActivityLinesTable: {
            truckFleetNumber: 'Truck fleet no.',
            trailerFleetNumber: 'Trailer fleet no.',
            deleteButton: 'Remove trip activity from the current purchase invoice',
            markNotInvoiceableButton: 'Mark trip activity as \'not invoiceable\'',
            addButton: 'Add correction line',
            deleteCorrectionButton: 'Remove correction',
            exclamation: 'This trip activity is already included in another purchase invoice',

        },
        history: {
            sentBy: 'send the self bill to the supplier',
            createdBy: 'created the self bill'
        }
    },
    purchaseInvoiceTerminalTransporter: {
        showFindBookingModalButton: 'Find Bookings',
        field: {
            id: {
                label: 'Id',
            },
            terminalTransporter: {
                label: 'Terminal Transporter',
            },
            bookingLines: {
                label: 'Bookings',
            },
        },
        overview: {
            trailerFleetNumber: 'Trailer no.',
            bookingId: 'Booking Id',
        }
    },
    purchaseInvoiceTerminalTransporterBookingLine: {
        field: {
            id: {
                label: 'Booking Id',
            },
            amount: {
                label: 'Invoice amount',
            },
        },
        deleteButton: 'Remove row from purchase invoice',
    },
    purchaseInvoiceSubcontract: {
        showFindSubcontractsModalButton: 'Find Subcontracts',
        field: {
            id: {
                label: 'Id',
            },
            truckingCompany: {
                label: 'Trucking Company',
            },
            orderLines: {
                label: 'Order Lines',
            },
        },
    },
    purchaseInvoiceSubcontractOrderLine: {
        field: {
            id: {
                label: 'Id',
            },
            amount: {
                label: 'Amount',
            },
        },
    },
    truckingCompany: {
        overview: {
            title: 'Transport company overview',
            addButton: 'Add transport company',
            truckCount: 'Truck count',
        },
        create: {
            title: 'Create transport company',
        },
        edit: {
            title: 'Transport company - {{name}}',
            addPurchaseInvoiceButtonTooltip: 'Add Purchase Invoice',
        },
        trucks: {
            add: 'Add truck',
        },
        trailer: {
            add: 'Add trailer',
        },
        contract: {
            add: 'Add contract',
        },
        tab: {
            trucks: 'Trucks',
            trailers: 'Trailers',
            contracts: 'Contracts',
        },
        field: {
            name: {
                label: 'Name',
                placeholder: 'Fill in a name',
            },
            creditorNumber: {
                label: 'Creditor number',
                placeholder: 'Fill in a creditor number',
            },
            colorCompany: {
                label: 'Company color',
                placeholder: 'choose color',
            },
            emailAddress: {
                label: 'Email Address',
                placeholder: 'e-mail',
            },
            type: {
                label: 'Type',
                value: {
                    own: 'Own',
                    subcontractor: 'Subcontractor',
                    haulier: 'Haulier',
                    charter: 'Charter',
                }
            },
            phone: {
                label: 'Telephone',
                placeholder: 'phone number',
            },
            location: {
                label: 'Country Code',
            },
            iban: {
                label: 'IBAN',
                placeholder: 'Fill in a IBAN number',
            },
            swiftBic: {
                label: 'SWIFT / BIC code',
                placeholder: '',
            },
            chamberOfCommerce: {
                label: 'Chamber of Commerce',
                placeholder: '',
            },
            vatCode: {
                label: 'VAT code',
                placeholder: '',
            },
            specialization: {
                label: 'Specialization',
            },
            paymentTerms: {
                label: 'Payment Terms (in days)',
            },
            blacklisted: {
                label: 'Blacklisted',
                options: {
                    all: 'All',
                    blacklisted: 'Yes',
                    notBlacklisted: 'Not',
                }
            },
            blacklistedReason: {
                label: 'Blacklisted reason',
            },
            contact: {
                label: 'Contact',
                placeholder: 'Select contact type',
                value: {
                    dayshift: 'Dayshift',
                    invoice: 'Invoice',
                    nightshift: 'Nightshift',
                    administration: 'Administration',
                    general: 'General',
                    operation: 'Operation'
                }
            },
            signedWageAgreement: {
                label: 'Signed min. wage agreement',
            },
            signedAgreementExpiryDate: {
                label: 'Signed agreement expiry date',
            },

            liabilityInsurance: {
                label: 'Liability and insurance',
            },
            liabilityInsuranceExpiryDate: {
                label: 'Liability insurance expiry date',
            },
            letterheadExpiryDate: {
                label: 'Letterhead expiry date'
            },
            euLicenseForGoodsTransportationExpiryDate: {
                label: 'EU license expiry date'
            },
            cmrInsurancePolicyExpiryDate: {
                label: 'CMR insurance policy expiry date'
            },
            licenses: {
                label: 'Licenses',
            },
            letterhead: {
                label: 'Letterhead',
            },
            eu_license_for_goods_transportation: {
                label: 'EU license for goods transportation',
            },
            cmr_insurance_policy: {
                label: 'CMR insurance policy',
            },
            licensesExpiryDate: {
                label: 'Licenses expiry date',
            },
            subcontractor: {
                label: 'Subcontractor',
            },
            priority: {
                label: 'Priority',
                instruction: 'Higher company priority will show it and it\'s assets higher in the lists',
            },
            owner: {
                label: 'Owner',
                options: {
                    all: 'All',
                    own: 'Own',
                    subcontractors: 'Contractors',

                }
            }
        },
    },
    truckingCompanyPurchaseInvoice: {
        purchaseInvoiceModal: {
            header: 'Purchase Invoice',
        },
    },
    truckingCompanyPurchaseInvoiceActivityLineItem: {
        field: {
            invoiceableKms: { label: 'kms' },
            kmAmount: { label: 'KM Amount' },
        },
    },
    trip: {
        overview: {
            id: 'Id',
            title: 'Trip overview',
            type: 'Type',
            trailer: 'Trailer fleet number',
            truck: 'Truck fleet number',
            order: 'Order',
            addButton: 'Add activity',
            addButtonAbove: 'Add activity above',
            addButtonTrip: 'Add trip',
            tripCount: 'Trip count',
            tripTrailerFleetNumber: 'Truck/Trailer Fleet number',
            owner: 'Owner',
            assignedTo: 'Assigned to',
            activityCity: 'Activities',
            status: 'Status',
            openOrders: 'open orders',
            parent: 'Parent',
            children: 'Children',
        },
        create: {
            title: 'Create trip',
        },
        edit: {
            title: 'Trip edit',
        },
        types: {
            truck: 'Truck',
            trailer: 'Trailer',
            order_subcontract: 'Subcontracted',
            sales: 'Sales',
        },
        trucks: {
            add: 'Add truck',
        },
        trailer: {
            add: 'Add trailer',
        },
        tab: {
            trucks: 'Trucks',
            trailers: 'Trailers',
        },
        activity: {
            types: {
                load: 'Load',
                unload: 'Unload',
                ferry: 'Ferry',
                terminal_drop: 'Terminal drop',
                terminal_pick_up: 'Terminal pick up',
                yard_drop: 'Yard drop',
                yard_pick_up: 'Yard pick up',
                dalessi_activity: 'Dalessi activity',
                customer_activity: 'Customer activity',
                truck_pick_up: 'Truck pick up',
                truck_service: 'Truck service',
                trailer_service: 'Trailer service',
            },
            requiredActivity: {
                label: 'One activity is required'
            },
        },
        field: {
            id: {
                label: 'Id'
            },
            activityId: {
                label: 'Act. Id'
            },
            name: {
                label: 'Name',
                placeholder: 'Fill in a name',
            },
            status: {
                label: 'Status'
            },
            truck: {
                label: 'Choose truck'
            },
            trailer: {
                label: 'Choose trailer'
            },
            order: {
                label: 'Order'
            },
            tripType: {
                label: 'Trip type'
            },
            IdPosition: {
                label: 'Id / Position'
            },
            position: {
                label: 'Position'
            },
            type: {
                label: 'Type'
            },
            location: {
                label: 'Location'
            },
            date: {
                label: 'Date'
            },
            dateFrom: {
                label: 'Date from'
            },
            dateUntil: {
                label: 'Date until'
            },
            ordering: {
                label: 'Nr.'
            },
            reference: {
                label: 'Reference'
            },
            instructions: {
                label: 'Instructions'
            },
            plannedKm: {
                label: 'Km'
            },
        }
    },
    tripActivity: {
        overview: {
            title: 'Trip activities',
            purchaseInvoice: 'P.Inv.',
            missingStatusFinishedAt: {
                label: 'Missing status finished at',
                value: {
                    all: 'All',
                    yes: 'Yes',
                    no: 'No',
                }
            },
            finalizeLoads: 'Finalize loads',
            finalizeLoadsNotificationText: 'Finalizing loads called'
        },
        field: {
            activityId: {
                label: 'Act. Id.'
            },
            id: {
                label: 'Id',
            },
            prevActivity: {
                label: 'Previous Activity',
            },
            activity: {
                label: 'Activity',
            },
            activityFromTo: {
                label: 'Activity From -> To',
            },
            plannedKm: {
                label: 'Km',
            },
            purchasePrice: {
                label: 'Purchase price'
            },
            additionalCost: {
                label: 'Additional Cost'
            },
            additionalCostShort: {
                label: 'Add. Cost'
            },
            trailer: {
                label: 'Trailer'
            },
            truck: {
                label: 'Truck'
            },
            truckLicensePlate: {
                label: 'Truck license plate'
            },
            truckingCompany: {
                label: 'Trucking company'
            },
            location: {
                label: 'City'
            },
            purchaseInvoiceStatus: {
                label: 'Purchase invoice status'
            },

        }
    },
    tripActivities: {
        field: {
            plannedKm: {
                label: 'Planned Km',
            },
            plannedKmFrom: {
                label: 'Planned Km From',
            },
            plannedKmTo: {
                label: 'Planned Km To',
            },
        }
    },
    expandRow: {
        content: {
            order: 'Order',
            trip: 'Trip',
            orderNumber: 'Order number',
            location: 'Location',
            city: 'Current location',
            gpsCoordinates: 'GPS coordinates',
            datesAndTimes: 'Dates and Times',
            plannedArrivalTime: 'Planned arrival time',
            estimated: 'Estimated (ETA)',
            remarks: 'Remarks',
            nextService: 'Next service',
            emailHaulier: 'Email of haulier',
            setPlanned: 'Set activity and trip as planned',
            service: 'Service',
            serviceType: 'Type',
        },
        contact: {
            title: 'Contact'
        },
        sendToYard: {
            title: 'Send to Yard'
        },
        sendTerminal: {
            title: 'Send Terminal'
        },
        editTrip: {
            title: 'Edit trip',
            sendActivities: 'Send activities',
            activitiesSended: 'Activities ware successfully sent',
            error: {
                selectActivity: 'Select at least one activity',
                setTruckMail: 'Truck company don\'t have address email',
            },
            activitySended: 'Activity was sent',
            activityAccepted: 'Activity ware accepted',
            activityRejected: 'Activity ware rejected',
        },
        trip: {
            title: 'Trip Route Schedule'
        },
        activity: {
            modal: {
                sendStatusEmail: {
                    header: 'Send status email',
                    trigger: 'Send status email'
                },
            },
            updateStatus: 'Update Status',

            buttons: {
                sendInstructions: 'Send instructions',
                draft: 'Change to Draft',
                planned: 'Plan activity',
                eta: 'Start driving',
                arrived: 'Driver Arrived',
                waiting: 'Driver Waiting',
                started: 'Started Activity',
                finished: 'Finished Activity',
                finalized: 'Finalize Activity',
                canceled: 'Cancel Activity',
                yardDropYardPickUp: 'Yard Drop / Yard Pick up',
                changeLocationActivity: 'Change location activity',
                changeDateActivity: 'Change activity date',
                splitAfter: 'Split after',
            },
            addButton: 'Add activity',
            deleteActivityButton: 'Delete activity',
            new: 'New',
        },
        newActivity: {
            information: 'New Activity Information',
            details: 'Details',
        },
        ferry: {
            cancelBooking: 'Cancel Booking',
            book: 'Booking',
            status: 'Booking Status',
            bookingReference: 'Booking ref.',
            mrnNumber: 'Document no',
            licensePlate: 'License plate',
            cargoDescription: 'Cargo description'
        },
        customs: {
            id: 'ID',
            title: 'Customs status',
            goToCustoms: 'Go to customs',
            systemTitle: 'Customs system',
            portbaseTitle: 'Portbase',
            customsRegistered: 'Customs registered',
            customsInfoReceived: 'Customs info received',
            bookingKnown: 'Booking created',
            gateIn: 'Gate In',
            arrived: 'Arrived',
            released: 'Released',
            discharged: 'Discharged',
            status: 'Status',
            arrivalNotification: 'Arrival Notification Customs',
            releaseNotification: 'Release Notification',
            loadConfirmation: 'Load confirmation',
            manifest: 'Manifest',
            exportNotificationTitle: 'Notification Export',
            popup: {
                status: 'Customs status:',
                manualStatus: 'Manual customs status:',
                noFerryTitle: 'No ferry in previous order',
                noFerryNote: 'The previous order does not have a ferry activity and hence no customs status.',
                value: {
                    ready: 'ready',
                    not_ready: 'not ready',
                    unknown: 'not known',
                    undefined: '',
                },
                note: 'The customs status of the ferry activity in the previous order is',
                noteManual: 'Note that this status was set through a manual override.',
                clearManual: 'Clear manual status'
            }
        },
        truck: {
            noTruck: 'Currently there is no truck attached',
            generalInformation: 'Truck information',
            sendPlan: 'Send plan to truck',
            activitiesTruck: {
                header: 'Activities truck',
                order: 'Order',
                activity: 'Activity',
                trailer: 'Trailer',
                location: 'Location',
                history: 'History',
                status: 'Status',
                actions: 'Actions',
            }
        },
        trailer: {
            noTrailer: 'Currently there is no trailer attached',
            generalInformation: 'Trailer information',
            performanceInformation: 'Performance',
            activitiesTrailer: {
                header: 'Trailer trips',
                order: 'Order',
                truck: 'Truck',
                unloadingCity: 'Unloading city',
                loadingCity: 'Loading city',
                tripDistance: 'Kilometers Trailer Trip',
            }
        },
        driver: {
            generalInformation: 'Driver tacho statistics',
        },
    },
    district: {
        overview: {
            title: 'District',
        },
        field: {
            id: {
                label: 'Id',
            },
            name: {
                label: 'Name area',
            },
            area: {
                label: 'Polygon',
            },
        }
    },
    history: {
        title: {
            label: 'History'
        },
        field: {
            user: {
                label: 'User'
            },
            time: {
                label: 'Time'
            },
            model: {
                label: 'Model'
            },
            field: {
                label: 'Field'
            },
            old: {
                label: 'Old'
            },
            new: {
                label: 'New'
            },
            id: {
                label: 'Id'
            }
        }
    },
    task: {
        create: {
            title: 'Add Task',
        },
        edit: {
            title: 'Task {{id}}',
            markAsDone: 'Mark as done',
        },
        overview: {
            addButton: 'New Task',
            title: 'Tasks',
        },
        field: {
            id: {
                label: 'Task ID',
            },
            status: {
                label: 'Status',
            },
            tags: {
                label: 'Tags',
            },
            subject: {
                label: 'Subject',
            },
            description: {
                label: 'Description',
            },
            dueDate: {
                label: 'Due date & time',
            },
            dueDateShort: {
                label: 'Due',
            },
            assigned: {
                label: 'Assignee',
            },
            subscribers: {
                label: 'Subscribers',
            },
            createdAt: {
                label: 'Created at'
            },
            createdBy: {
                label: 'Submitter'
            },
            tagTrucks: {
                label: 'Trucks',
            },
            tagTrailers: {
                label: 'Trailers',
            },
            tagTasks: {
                label: 'Tasks',
            },
            tagOrders: {
                label: 'Orders',
            },
            tagInvoices: {
                label: 'Invoices',
            },
            tagBookings: {
                label: 'Bookings',
            },
            tagActivities: {
                label: 'Activities',
            },
            tagServices: {
                label: 'Services',
            },
            tagPurchaseInvoices: {
                label: 'Purchase Invoices',
            },

        },
        sidebar: {
            title: 'Tasks',
            openOverview: 'Open overview',
            editTask: 'Edit task',
            createdBy: 'created by',
        },
        button: {
            add: 'Add task'
        }
    },
    contact: {
        field: {
            emailAddress: {
                label: 'Client invoices email address'
            }
        }
    },
    modal: {
        closePdfButton: 'Close',
        downloadPdfButton: 'Download',
        downloadAll: 'Download All',
        download: 'Download',
        copyToClipboard: 'Copy to clipboard',
        copiedSuccessfully: 'Copied successfully',
        saveAndDownloadAll: 'Save & Download PDFs',
        sendDraftButton: 'Send',
        saveAndClose: 'Save & Close',
        sendInformation: 'Email successfully sent',
        sendInformationWithoutAttachment: 'Email sent without attachment',
        attachmentToLarge: 'The attachment is to large and can\'t be send',
        errorSendMessage: 'Problem to send message',
        nextButton: 'Next',
        previousButton: 'Previous',
        sendEmail: {
            recipients: {
                label: 'Recipients',
            },
            recipientsPod: {
                label: 'POD Recipients',
            },
            subject: {
                label: 'Subject',
            },
            content: {
                label: 'Message',
            },
            template: {
                label: 'Template',
            },
        },
        activitySendModal: {
            additionalCost: {
                label: 'Cost'
            },
            plannedKm: {
                label: 'Planned KM'
            },
            plannedMiles: {
                label: 'Planned MI'
            },
            activity: {
                label: 'Activity'
            },
        }
    },
    invoice: {
        overview: {
            title: 'Invoices overview',
        },
        statuses: {
            new: 'New',
            accepted: 'Accepted',
            rejected: 'Rejected',
            completed: 'Completed',
        },
        create: {
            title: 'Add Invoice',
        },
        edit: {
            title: 'Edit Invoice',
            orders: 'Orders',
            addInvoiceLine: 'Add invoice line',
            addInvoiceCustomLine: 'Add invoice custom line',
            previewSavePdf: 'Save & Preview PDF',
            resend: 'Resend to Unit4',
            sendDraftSaveButton: 'Save & Send ',
            markAsPaid: 'Mark invoice as Paid',
            downloadPdfButton: 'Download PDF',
            downloadInvoicePdfButton: 'Download Invoice PDF',
            customerPortalDownloadInvoicePdfButton: 'Download Invoice {{invoiceNumber}}',
            downloadSubContractPdfButton: 'Download SubContract PDF',
            createCreditInvoice: 'Create credit invoice',
            creditInvoice: 'Credit invoice',
            currencyMessage: 'Invoice has different currency',
            ledgerCodeMessage: 'Invoice has different ledger code',
            multipleOrders: 'Invoice references multiple different orders',
            multipleLines: 'Invoice should contains at least one invoice line',
            currencyNotification: 'You can change invoice currency, but be aware that changed currency will take impact only for invoice not for order:',
        },
        modal: {
            sendEmail: {
                subject: {
                    text: 'Invoice Dalessi no. ',
                },
                content: {
                    text: 'In the attached we send you a draft of invoice to approve.',
                },
            },
            saveModal: {
                title: '{{action}} Invoice',
                description: 'Are you sure you want to {{action}} an invoice with a total of {{amount}}?',
                saveWithoutOrder: 'Are you sure you want to save this invoice without linking it to an order?',
                actionPreposition: ' of',
            },
            modalConfirmation: {
                title: 'Invoice documents',
                description: 'The total size of attachments exceeds 20MB and may not be received by the client mail server.',
            }
        },
        field: {
            id: {
                label: 'Invoice no.',
            },
            status: {
                label: 'Status',
            },
            customer: {
                label: 'Customer',
                placeholder: 'Customer',
            },
            invoiceNumber: {
                label: 'No. invoice',
            },
            invoiceDate: {
                label: 'Date',
            },
            ledgerCode: {
                label: 'Ledger code',
            },
            order: {
                label: 'Order',
            },
            debtorNumber: {
                label: 'Debtor no.',
            },
            reference: {
                label: 'Reference',
            },
            includeVAT: {
                label: 'VAT Included',
            },
            totalPrice: {
                label: 'Total price',
            },
            totalPriceFrom: {
                label: 'Total From',
            },
            totalPriceTo: {
                label: 'Total To',
            },
            totalNetPrice: {
                label: 'Sales price'
            },
            draftSentAt: {
                label: 'Sent At'
            },
            vat: {
                label: 'VAT'
            },
            description: {
                label: 'Description',
                placeholder: 'Put description here'
            },
            notes: {
                label: 'Notes',
                placeholder: 'Add notes'
            },
            remarks: {
                label: 'Document internal remarks (this field won\'t be send to client)',
                placeholder: 'Add remarks'
            },
            documents: {
                label: 'Upload documents'
            },
            orderDocuments: {
                label: 'Order documents'
            },
            proofOfDelivery: {
                label: 'Proof of delivery'
            },
            invoiceDocuments: {
                label: 'Invoice documents'
            },
            date: {
                label: 'Invoice date'
            },
        },
        vatRules: {
            vatFirstRule: 'VAT out of scope',
            vatSecondRule: 'VAT Reverse Charge. Article 196 VAT Directive',
            vatThirdRule: 'Transporte aus und nach Grossbritannien ind gem. § 4 i. V. m § 1 UStG steuerfrei',
        },
        paidInAdvance: {
            message: 'This invoice needs to be paid in advance, otherwise we cannot collect the cargo.',
        }
    },
    invoiceLine: {
        field: {
            order: {
                label: 'Order id'
            },
            orderNumber: {
                label: 'Order number'
            },
            orderReference: {
                label: 'Order reference'
            },
            fromLocation: {
                city: {
                    label: 'From'
                }
            },
            toLocation: {
                city: {
                    label: 'To'
                }
            },
            km: {
                label: 'KM'
            },
            vat: {
                label: 'VAT'
            },
            total: {
                label: 'Total'
            }
        }
    },
    orderTable: {
        header: {
            invoiceLabel: 'Invoice',
            subjectLabel: 'Subject',
            amountLabel: 'Amount',
        },
        wholeAmount: 'Total invoiced amount',
        differentCurrency: 'Order contains different invoice currency'
    },
    integrationLogger: {
        overview: {
            title: 'Integration logs',
        },
        field: {
            id: {
                label: 'Id',
            },
            createdAt: {
                label: 'Created at',
            },
            entity: {
                label: 'Entity',
            },
            entityId: {
                label: 'Entity Id',
            },
            type: {
                label: 'Type',
            },
            endpoint: {
                label: 'Endpoint',
            },
            method: {
                label: 'Method',
            },
            request: {
                label: 'Request',
            },
            response: {
                label: 'Response',
            },
            error: {
                label: 'Error',
            },
        }
    },
    communicationEmailTemplate: {
        overview: {
            title: 'Email template overview',
            addButton: 'Add new e-mail template',
        },
        edit: {
            title: 'Edit email template',
        },
        field: {
            id: {
                label: 'Id',
            },
            name: {
                label: 'Name',
            },
            subject: {
                label: 'Subject',
            },
            senderEmail: {
                label: 'Sender',
            },
            userMessage: {
                label: 'User_message',
            },
            manageAttachments: {
                label: 'Option to add order documents to the email',
                options: {
                    enabled: 'Enabled',
                    disabled: 'Disabled',
                }
            }
        }
    },
    activityStatusEmailTemplate: {
        overview: {
            title: 'Activity templates overview',
            addButton: 'Add new activity e-mail template',
        },
        edit: {
            title: 'Edit activity email template',
        },
        field: {
            id: {
                label: 'Id',
            },
            status: {
                label: 'Status',
            },
            emailTemplate: {
                label: 'Template',
            },
            name: {
                label: 'Name',
            },
            subject: {
                label: 'Subject',
            },
            userMessage: {
                label: 'Message',
            }
        }
    },
    unit4: {
        ok: {
            title: 'Unit4 connection successful',
            body: 'Unit4 is successfully connected.',
        },
        error: {
            title: 'Unit 4 connection failed',
            body: {
                invalid_request: 'Sending request to Unit4 failed.',
                invalid_client: 'Sending client to Unit4 failed.',
                invalid_grant: 'Sending grant to Unit4 failed.',
                invalid_scope: 'Sending cope to Unit4 failed. ',
                unauthorized_client: 'Sending unauthorized client to Unit4.',
                unsupported_grant_type: 'Sending grant to Unit4 failed.',
            },
        },
        overview: {
            title: 'Unit4 Integration',
        },
        button: {
            synchronize: 'Synchronize'
        },
    },
    unit4Integration: {
        field: {
            id: {
                label: 'Id',
            },
            administration: {
                label: 'Administration',
            },
            active: {
                label: 'Active',
            },
            inActive: {
                label: 'Inactive',
            },
            invoice: {
                label: 'Invoice',
            },
            errorReason: {
                label: 'Error reason',
            },
            payLoad: {
                label: 'Payload',
            },
            performedAt: {
                label: 'Performed at',
            },
            createdAt: {
                label: 'Created at',
            },
            updatedAt: {
                label: 'Updated at',
            },
        }
    },
    startError: {
        maintenance: 'Server maintenance. Please wait...',
        line1: 'Unknown error ocurred.',
        line2: 'Please contact support.',
    },
    eta: {
        fields: {
            header: 'ETA',
            rest: 'Rest included',
            distance: 'Distance',
            destination: 'Destination',
            id: 'ID',
        },
    },
    metabase: {
        filter: {
            both: 'Both',
            yes: 'Yes',
            no: 'No'
        },
        field: {
            acties: {
                label: 'Actions'
            },
            groups: {
                label: 'Permission groups'
            },
            sidebarEnabled: {
                label: 'Sidebar active'
            },
            enabled: {
                label: 'Active'
            },
            sequenceNumber: {
                label: 'Navigation bar sequence',
                info: 'At which location relative to other metabases the link to this metabase should appear in the appheader, a lower number means that the metabase appears more to the left in the computer version and at the top of the mobile version.'
            },
            id: {
                'label': 'ID'
            },
            name: {
                'label': 'Name'
            },
            dashboardId: {
                label: 'Dashboard ID',
                info: 'The metabase dashboard id, this id number can be found in the url of your dashboard on metabase. e.g. metabase-url.com/dashboard/ID'
            },
            updatedAt: {
                label: 'Last update'
            },
            users: {
                label: 'Users'
            },
            clients: {
                label: 'Customers'
            },
            params: {
                label: 'Parameters',
                info: 'The parameters that are sent to the metabase dashboard, the following names should be used for the parameter in the dashboard:\n user, \n main_branch, \n branches, \n client',
                value: {
                    user: 'User',
                }
            }
        }
    },
    costCalculation: {
        overview: {
            title: 'Cost Calculation page',
        },
        variation: {
            exchange_rate: 'Exchange Rate',
            europe_mainland: 'Europe Mainland',
            north_uk: 'North UK',
            south_uk: 'South UK',
            ireland: 'Ireland',
        },
        field: {
            dateValidFrom: {
                label: 'Date valid from'
            },
            dateValidTo: {
                label: 'Date valid to'
            },
            changeBy: {
                label: 'Change by'
            },
            exchangeRate: {
                label: 'Exchange rate'
            },
            exchange_rate: {
                label: 'Exchange rate (£/€)',
                update: 'Update exchange rate'
            },
            kmPrice: {
                label: 'KM price (€)'
            },
            europe_mainland: {
                label: 'Average KM purchase price - Europe mainland',
                update: 'Update KM price'
            },
            north_uk: {
                label: 'Average KM purchase price - North UK',
                update: 'Update KM price'
            },
            south_uk: {
                label: 'Average KM purchase price - South UK',
                update: 'Update KM price'
            },
            ireland: {
                label: 'Average KM purchase price - Ireland',
                update: 'Update KM price'
            }
        }
    },
    driverActivityTemplate: {
        overview: {
            title: 'Driver Activity Template page',
        },
        headers: {
            type: 'Input type',
            label: 'Label',
            details: 'Additional instructions',
            optional: 'Required',
        },
        save: 'Save'
    },
    surcharge: {
        field: {
            code: { label: 'Code' },
            name: { label: 'Surcharge name' },
            relatedSurcharge: { label: 'Of surcharge' },
            type:{
                label: 'Type',
                fixed: 'Fixed price',
                percentage: 'Percentage',
                ppkm: 'Price per kilometer',
                amount: 'Price per amount',
            }
        },
        add: {
            header: 'Add surcharge',
            button: {
                label: 'Add surcharge'
            }
        },
    },
    surchargeAmount: {
        field: {
            id: {
                label: 'ID'
            },
            code: {
                label: 'Code'
            },
            name: {
                label: 'Surcharge name'
            },
            amount: {
                label: 'Amount'
            },
            percentage: {
                label: 'Percentage'
            },
            validFrom: {
                label: 'Valid from'
            },
            validTo: {
                label: 'Valid until (optional)'
            },
            expiresNote: {
                label: 'Expires each'
            },
            updatedAt: {
                label: 'Last updated'
            },
            currency: {
                label: 'Currency'
            },
            status: {
                label: 'Status'
            },
            relatedSurcharge: { label: 'Of related surcharge' },

        },
        filters:{
            validRange: {
                label: 'Validity',
                clearButton: {
                    label: 'Clead date filter'
                }
            }
        },
        status: {
            future: 'Future',
            current: 'Current',
            all: 'All',
            expired: 'Expired'
        },
        edit: {
            title: 'Edit surcharge'
        },
        create: {
            title: 'Add surcharge',
        },
        add: {
            button: {
                label: 'Add surcharge'
            }
        },
        copy: {
            header: 'Copy surcharge'
        },
        overview: {
            title: 'Surcharge overview'
        },
        tooltip: {
            edit: 'Edit',
            editDisabled: 'Can only edit a surcharge amount if it is in the future',
            copy: 'Copy',
            update: 'Update',
            noDelete: 'Cannot delete the current or an expired value',
            delete: 'Delete'
        },
        deactivate: {
            label: 'Schedule deactivation',
            modal: {
                description: 'Please enter the date from which this surcharge should be deactivated'
            }
        }

    },
    serviceSubtype: {
        edit: {
            section: {
                general: {
                    label: 'General'
                },
                recurrence: {
                    label: 'Rules of service/maintenance recurrence'
                },
                documents: {
                    label: 'Documents'
                },
                location: {
                    label: 'Location'
                }
            }
        },
        choice: {
            option: {
                yes: {
                    label: 'Yes'
                },
                no: {
                    label: 'No'
                },
                weeks: {
                    label: 'Weeks'
                },
                years: {
                    label: 'Years'
                },
                months: {
                    label: 'Months'
                },
                executed_at: {
                    label: 'Execution date'
                },
                previous_service_expired_at: {
                    label: 'Expiration date of previous service'
                },
            }
        },
        field: {
            ownership: {
                label: 'Asset owners',
                option: {
                    all: {
                        label: 'All'
                    },
                    specific: {
                        label: 'Specific'
                    }
                }
            },
            owners: {
                label: 'Owners',
            },
            asset: {
                label: 'Asset',
                option: {
                    truck: {
                        label: 'Truck'
                    },
                    trailer: {
                        label: 'Trailer'
                    }
                },
                placeholder: 'Input',
            },
            name: {
                label: 'Subtype name',
                placeholder: 'Input',
            },
            recurrent: {
                label: 'Is recurrent?'
            },
            hasLocations: {
                label: 'Has standard locations?'
            },
            allowDocuments: {
                label: 'Enable adding documents'
            },
            allowInvoices: {
                label: 'Enable adding invoices'
            },
            expiration: {
                placeholder: {
                    amount: 'Amount',
                    unit: 'Time frame'
                },
                description: 'This service/maintenance expires after <0/> <1/>.',
            },
            newBeforeExpiration: {
                placeholder: {
                    amount: 'Amount',
                    unit: 'Time frame'
                },
                description: 'A new service/maintenance item is created <0/> <1/> before the expiry date.',
            },
            raisePriorityBeforeExpiration: {
                placeholder: {
                    amount: 'Amount',
                    unit: 'Time frame'
                },
                description: 'Raise the priority of the service/maintenance item <0/> <1/> before the expiry date.',
            },
            locations: {
                error: {
                    empty: 'At least one location is required'
                },
                button: {
                    addLocation: 'Add standard location'
                },
                field: {
                    name: {
                        label: 'Name',
                        placeholder: 'Name'
                    },
                    location: {
                        label: 'Address',
                        placeholder: 'Address'
                    }
                }
            },
            recurrenceRules: {
                label: 'Recurrence rules'
            },
            expirationBasedOn: {
                label: 'Expiration based on',
                placeholder: 'Expiration based on',
                description: 'Expiration date based on <0/>.',
            }
        },
        create: {
            title: 'Add service subtype'
        },
        new: {
            title: 'New subtype'
        },
        modal: {
            fieldChanged: {
                header: 'Change general service info',
                content: `<0>Are you sure you want to change the {{fields}} of a subtype?</0>
                <1>The change will be effective on the service items that still need to be created, but will <1>not</1> influence existing service items.</1>`
            },
        }
    },
    serviceType: {
        create: {
            title: 'Add service & maintenance type'
        },
        field: {
            name: {
                label: 'Type name'
            },
            color: {
                label: 'Type color',
                option: {
                    blue: {
                        label: 'Blue'
                    },
                    red: {
                        label: 'Red'
                    },
                    yellow: {
                        label: 'Yellow'
                    },
                    orange: {
                        label: 'Orange'
                    },
                    green: {
                        label: 'Green'
                    },
                    teal: {
                        label: 'Teal'
                    },
                    purple: {
                        label: 'Purple'
                    },
                    gray: {
                        label: 'Gray'
                    }
                }
            },
            subCategories: {
                label: 'Subcategories'
            }
        },
        overview: {
            title: 'Service and maintenance settings',
            addButton: 'Add service'
        },
        edit: {
            title: 'Edit service & maintenance type'
        }
    },
    truckDataSource: {
        edit: {
            title: 'List of data provider',
        },
        field: {
            truck: {
                label: 'Truck'
            },
            dataSource: {
                label: 'Data provider',
            },
            dataExternalId: {
                label: 'Data reference ID',
                placeholder: 'Data reference ID',
            },
            addTruckDataSource: {
                label: 'Add new data provider',
            },
            activePosition: {
                label: 'Active position',
            },
            activeEta: {
                label: 'Active eta',
            },
            activeMessage: {
                label: 'Active chat',
            },
        },
        info: {
            cannot_change_item: 'This features is not available for this provider'
        }
    },
    driverDataSource: {
        edit: {
            title: 'Driver data provider',
        },
        field: {
            driver: {
                label: 'Driver'
            },
            dataSource: {
                label: 'Data provider',
            },
            dataExternalId: {
                label: 'Data reference ID',
                placeholder: 'Data reference ID',
            },
            addDriverDataSource: {
                label: 'Add driver data provider',
            },
            activeTachoEvent: {
                label: 'Active tacho event',
            },
            activeMessage: {
                label: 'Active chat',
            },
        }
    },
    driverActionTemplate: {
        types: {
            text: 'Text field',
            numeric: 'Numeric input',
            image: 'Photo upload',
            check: 'Checkbox',
        }
    },
    driverAppActivity: {
        field: {
            status: {
                label: 'status',
            }
        }
    }
};
