import { Header, Segment } from 'semantic-ui-react';
import React from 'react';
import { t } from 'i18n';

export const ModuleDescription = () => (
    <Segment raised>
        <Header>{t('system.module.overview.installed')}</Header>
        <p>{t('system.module.overview.description')}</p>

        <p>{t('system.module.overview.moreInformation')} <a
            href="http://iae.docs.internal/_iae/user_guide/modules/overview.html">docs.internal</a></p>
    </Segment>
)
