import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { TAB_TITLE_SUFFIX } from 'helpers';
import { Helmet } from 'react-helmet';

@observer
export default class PageTitle extends Component {
    tabTitleSuffix = TAB_TITLE_SUFFIX;
    static propTypes = {
        title: PropTypes.string.isRequired,
    };


    render() {
        const { title } = this.props;
        if (title && this.tabTitleSuffix && typeof title === 'string') {
            return (
                <Helmet>
                    <title>{title} {this.tabTitleSuffix}</title>
                </Helmet>
            );
        } else {
            return null;
        }

    }
}
