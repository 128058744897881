import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Driver } from './Driver';
import { ACTION_NONE, ACTION_DRIVE, ACTION_WORK, ACTION_WAIT } from './enums/TachoEventAction';
import moment from 'moment';

function sortMeasuredAt(a, b) {
    if (a.measuredAt < b.measuredAt) {
        return -1;
    }

    if (a.measuredAt > b.measuredAt) {
        return 1;
    }

    return 0;
}

export class TachoEvent extends Model {
    static backendResourceName = 'tacho_event';

    @observable id = null;
    @observable action = ACTION_NONE;
    @observable point = { lng: '', lat: '' };
    @observable measuredAt = null;
    @observable dataExternalId = null;
    @observable dataSource = '';
    @observable endDate = null;

    casts() {
        return {
            measuredAt: Casts.datetime,
            endDate: Casts.datetime,
        };
    }

    relations() {
        return {
            driver: Driver,
        };
    }
}

export class TachoEventStore extends Store {
    Model = TachoEvent;
    static backendResourceName = 'tacho_event';

    driveDurationSeconds(start, end) {
        const sortedTachoEvents = this.models.slice().sort(sortMeasuredAt);
        const releventTachoEvents = sortedTachoEvents
            .map((tachoEvent, i) => ({
                start: moment(tachoEvent.measuredAt.toString()).clone(),
                end: i < sortedTachoEvents.length - 1 ? moment(sortedTachoEvents[i + 1].measuredAt.toString()).clone() : end.clone(),
                action: tachoEvent.action,
            }))
            .filter(tachoEvent => tachoEvent.action === ACTION_DRIVE)
            .filter(tachoEvent => tachoEvent.end > start );
        return releventTachoEvents.reduce((res, tachoEvent) => res + moment.min(tachoEvent.end, end).diff(moment.max(tachoEvent.start, start), 'seconds'), 0);
    }

    driveDurationAndWorkingSeconds(start, end) {
        const sortedTachoEvents = this.models.slice().sort(sortMeasuredAt);
        const releventTachoEvents = sortedTachoEvents
            .map((tachoEvent, i) => ({
                start: moment(tachoEvent.measuredAt.toString()).clone(),
                end: i < sortedTachoEvents.length - 1 ? moment(sortedTachoEvents[i + 1].measuredAt.toString()).clone() : end.clone(),
                action: tachoEvent.action,
            }))
            .filter(tachoEvent => tachoEvent.action === ACTION_DRIVE || tachoEvent.action === ACTION_WORK || tachoEvent.action === ACTION_WAIT)
            .filter(tachoEvent => tachoEvent.end > start );


        return releventTachoEvents.reduce((res, tachoEvent) => res + moment.min(tachoEvent.end, end).diff(moment.max(tachoEvent.start, start), 'seconds'), 0);
    }
}
