import { Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { CustomerRoute } from './CustomerRoute';
import { Location } from 'store/Location';

export class CustomerRouteActivityTemplate extends Model {
    static backendResourceName = 'customer_route_activity_template';

    @observable id = null;
    @observable type = '';
    @observable companyName = '';
    @observable ordering = 0;

    @observable saveAddress = false; // Helper to save company-address pair when saving job model

    relations() {
        return {
            customerRoute: CustomerRoute,
            location: Location,
        };
    }

    setInput(field, value) {
        if (field === 'location' || field === 'companyName') {
            // Mark save address as touched.
            super.setInput('saveAddress', this.saveAddress);
        }
        super.setInput(field, value);
    }

}

export class CustomerRouteActivityTemplateStore extends Store {
    Model = CustomerRouteActivityTemplate;
    comparator = 'ordering';
    static backendResourceName = 'customer_route_activity_template';
}

