import { Model, Store, Casts } from '@code-yellow/spider';
import { observable, computed } from 'mobx';
import { DateTime } from 'luxon';
import moment from 'moment';
import { TachoEvent, TachoEventStore } from './TachoEvent';
import { SERVER_DATETIME_FORMAT_FOR_TACHO } from 'helpers';

export const MAX_DAYS_WORK_15_HOURS = 3;
export const MAX_EXTENDED_SHIFTS_IN_WEEK = 2;

export const CAN_DRIVE_9_HOURS = 9;
export const CAN_DRIVE_10_HOURS = 10;
export const CAN_DRIVE_11_HOURS = 11;
export const CAN_DRIVE_13_HOURS = 13;
export const CAN_DRIVE_15_HOURS = 15;

export const BREAK_9_HOURS = '9:00h';
export const BREAK_11_HOURS = '11:00h';
export const BREAK_045_HOURS = '0:45h';

export class TachoStatistics extends Model {
    static backendResourceName = 'tacho_statistics';

    @observable id: number | null = null;
    @observable dataSource = '';
    @observable dataExternalId = '';
    @observable measuredAt = null;
    @observable dayStart: DateTime | null = null;
    @observable dayDrivingTime = null;
    @observable weekDrivingTime = null;
    @observable previousWeekDrivingTime = null;
    @observable reducedRestsInWeek = 0;
    @observable extendedShiftsInWeek = 0;
    @observable operationalWeekStartedAt: DateTime | null = null;
    @observable nextWeekendbreakLength = 'unknown';
    @observable lastShortBreakEnd: DateTime | null = null;
    @observable startOfCurrentRest: DateTime | null = null;
    @observable weeklyInfoCompiledAt: DateTime | null = null;
    @observable workDays = 5;
    @observable homeDays = 2;

    @observable _currentTachoEvent: TachoEvent = {} as TachoEvent;

    constructor(currentTachoEvent: TachoEvent) {
        super()
        if (currentTachoEvent) {
            this._currentTachoEvent = currentTachoEvent;
        }
    }

    get currentTachoEvent(): TachoEvent {
        return this._currentTachoEvent;
    }

    set currentTachoEvent(value: TachoEvent) {
        if (value) {
            this._currentTachoEvent = value;
        }
    }

    casts() {
        return {
            dayDrivingTime: Casts.durationMinutes,
            weekDrivingTime: Casts.durationMinutes,
            previousWeekDrivingTime: Casts.durationMinutes,
            operationalWeekStartedAt: Casts.datetime,
            dayStart: Casts.datetime,
            startOfCurrentRest: Casts.datetime,
            lastShortBreakEnd: Casts.datetime,
            weeklyInfoCompiledAt: Casts.datetime,
        }
    }

    @computed
    get weekendBreakHours() {
        if (this.nextWeekendbreakLength === 'short') {
            return 24;
        }
        if (this.nextWeekendbreakLength === 'long') {
            return 45;
        }
        else {
            return '?';
        }
    }

    @computed
    get weekendBreakStart() {
        if (this.operationalWeekStartedAt) {
            return moment(this.operationalWeekStartedAt.toString()).clone().add(144, 'hours');
        }
    }

    @computed get lastExternalActivity() {
        if (!this.currentTachoEvent || !this.currentTachoEvent.measuredAt) {
            return null;
        } else {
            return this.currentTachoEvent.measuredAt;
        }
    }

    updateWeeklyInfo() {
        this.wrapPendingRequestCount(this.api.post(this.url + 'driver/update_weekly_info/'));
    }

    getTachoEventMeasuredAtString() {
        if (!this.currentTachoEvent || !this.currentTachoEvent.measuredAt) {
            return '';
        }

        const value = this.currentTachoEvent?.measuredAt;

        return value ? (value as DateTime).toString() : '';
    }

    actualDayDrivingTime(now = moment()) {
        if (this.dayDrivingTime == null) {
            return null;
        } else if (!this.currentTachoEvent || this.currentTachoEvent.action !== 'drive') {
            return this.dayDrivingTime;
        } else {
            const dayDrivingTimeDuration = moment.duration(this.dayDrivingTime);
            const remainingMinutesAfterCompiledAt = now.clone().startOf('minute').diff(moment(this.weeklyInfoCompiledAt?.toString() || this.getTachoEventMeasuredAtString()).startOf('minute'), 'minutes');

            return dayDrivingTimeDuration.add(remainingMinutesAfterCompiledAt, 'minutes');
        }
    }

    remainingWeekDrivingTime(now = moment()) {
        const remainingMinutesAfterCompiledAt = now.clone().startOf('minute').diff(moment(this.weeklyInfoCompiledAt?.toString() || this.getTachoEventMeasuredAtString()).startOf('minute'), 'minutes');
        if (this.previousWeekDrivingTime == null && this.weekDrivingTime == null) {
            return 0;
        } else if (!this.currentTachoEvent || this.currentTachoEvent.action !== 'drive') {
            if (this.previousWeekDrivingTime && this.weekDrivingTime && this.previousWeekDrivingTime < this.weekDrivingTime) {
                return this.previousWeekDrivingTime
            } else if (this.previousWeekDrivingTime && this.weekDrivingTime &&this.previousWeekDrivingTime > this.weekDrivingTime) {
                return this.weekDrivingTime
            }
        } else {
            if (this.previousWeekDrivingTime && this.weekDrivingTime && this.previousWeekDrivingTime < this.weekDrivingTime) {
                const previousWeekDrivingTimeDuration = moment.duration(this.previousWeekDrivingTime);
                return previousWeekDrivingTimeDuration.subtract(remainingMinutesAfterCompiledAt, 'minutes')

            } else if (this.previousWeekDrivingTime && this.weekDrivingTime && this.previousWeekDrivingTime > this.weekDrivingTime) {
                const weekDrivingTimeDuration = moment.duration(this.weekDrivingTime);
                return weekDrivingTimeDuration.subtract(remainingMinutesAfterCompiledAt, 'minutes')
            }
        }
    }

    checkActualDayDrivingTime(now?: moment.Moment) {
        let actualDayDrivingTime = this.actualDayDrivingTime()?.asMinutes();
        if (now) {
            actualDayDrivingTime = this.actualDayDrivingTime(now)?.asMinutes();
        }
        if (actualDayDrivingTime) {
            return actualDayDrivingTime
        }

        return null;
    }

    @computed
    get nonExtendedRemainingDayDrivingTime() {
        if (this.dayDrivingTime === null) {
            return null;
        } else {
            const actualDayDrivingTime = this.checkActualDayDrivingTime();
            if (actualDayDrivingTime) {
                return moment.duration(Math.max(0, CAN_DRIVE_9_HOURS * 60 - actualDayDrivingTime), 'minutes');
            }
            return null;
        }
    }

    remainingDayDrivingTime(now = moment()) {
        if (this.dayDrivingTime === null) {
            return null;
        } else if (this.canDrive10Hours) {
            const actualDayDrivingTime = this.checkActualDayDrivingTime(now);
            if (actualDayDrivingTime) {
                return moment.duration(Math.max(0, CAN_DRIVE_10_HOURS * 60 - actualDayDrivingTime), 'minutes');
            }
            return null;
        } else {
            const actualDayDrivingTime = this.checkActualDayDrivingTime(now);
            if (actualDayDrivingTime) {
                return moment.duration(Math.max(0, CAN_DRIVE_9_HOURS * 60 - actualDayDrivingTime), 'minutes');
            }
            return null;
        }
    }

    @computed
    get canWork15Hours() {
        return this.reducedRestsInWeek < MAX_DAYS_WORK_15_HOURS;
    }

    @computed
    get work15HoursDaysRemaining() {
        return MAX_DAYS_WORK_15_HOURS - this.reducedRestsInWeek;
    }

    @computed
    get canDrive10Hours() {
        return this.extendedShiftsInWeek < MAX_EXTENDED_SHIFTS_IN_WEEK;
    }

    @computed
    get expectedRestHours() {
        return this.canWork15Hours ? CAN_DRIVE_9_HOURS : CAN_DRIVE_11_HOURS;
    }

    @computed
    get drive10HoursRemaining() {
        return MAX_EXTENDED_SHIFTS_IN_WEEK - this.extendedShiftsInWeek;
    }

    @computed
    get dayEnd() {
        const hours = this.canWork15Hours ? CAN_DRIVE_15_HOURS : CAN_DRIVE_13_HOURS;
        if (this.dayStart) {
            return moment(this.dayStart.toString()).clone().add(hours, 'hours');
        }
        return null;
    }

    @observable tachoEvents = new TachoEventStore();
    fetchLastDriverTachoEvents(sourceName, externalId) {
        if (externalId && sourceName) {
            const twoDaysAgo = moment().subtract({ days: 2000 });

            this.tachoEvents.params['.data_external_id'] = externalId;
            this.tachoEvents.params['.data_source'] = sourceName;
            this.tachoEvents.params['.measured_at:gte'] = twoDaysAgo.format(SERVER_DATETIME_FORMAT_FOR_TACHO)
            this.tachoEvents.params['limit'] = 100;
            this.tachoEvents.fetch();
        }
    }


    shortBreakStart(now) {
        const lastShortBreakEnd = (moment(this.lastShortBreakEnd?.toString()) || now).clone();
        const nextShortBreakStart = lastShortBreakEnd.clone().add(4.5, 'hours');
        const nextShortBreakIncludeWorkAndDrive = lastShortBreakEnd.clone().add(6, 'hours');

        if (!this.tachoEvents.models.length) {
            this.fetchLastDriverTachoEvents(this.dataSource, this.dataExternalId);
        }

        if (this.tachoEvents && this.dataExternalId) {
            const actualDrivingSinceLastShortBreak = this.tachoEvents.driveDurationSeconds(nextShortBreakStart.clone().subtract(4.5, 'hours'), now);
            const actualWorkingAndDrivingSinceLastShortBreak = this.tachoEvents.driveDurationAndWorkingSeconds(nextShortBreakIncludeWorkAndDrive.clone().subtract(6, 'hours'), now);

            const potentialDrivingSinceLastShortBreak = now.diff(lastShortBreakEnd, 'seconds');   //last short break_end, till now, in seconds

            const maxDrivingTime = 16200; //seconds. 4.5h
            const maxWorkingAndDrivingTime = 21600; //seconds. 6h

            const percentageOfDriving = actualDrivingSinceLastShortBreak *100 / maxDrivingTime;
            const percentageOfWorkingAndDriving= actualWorkingAndDrivingSinceLastShortBreak *100 / maxWorkingAndDrivingTime;

            // Move nextShortBreakStart by the amount of driving time not driven.
            if (potentialDrivingSinceLastShortBreak > 0) {
                nextShortBreakIncludeWorkAndDrive.add(potentialDrivingSinceLastShortBreak - actualWorkingAndDrivingSinceLastShortBreak, 'seconds');
                nextShortBreakStart.add(potentialDrivingSinceLastShortBreak - actualDrivingSinceLastShortBreak, 'seconds');
            }

            if (percentageOfWorkingAndDriving > percentageOfDriving) {
                return nextShortBreakIncludeWorkAndDrive
            }
        }

        return nextShortBreakStart;
    }

    nextBreakStart(now) {
        const dates: any[] = [
            this.dayEnd,
            this.weekendBreakStart,
            this.shortBreakStart(now),
        ].filter(Boolean);

        return moment.min(dates);
    }

    @computed
    get nextBreakHours() {
        const start = this.nextBreakStart(moment());
        if (start) {
            if (start.isSame(this.weekendBreakStart, 'minute')) {
                return this.weekendBreakHours
                    ? `${this.weekendBreakHours}:00h`
                    : '- h';
            }
            if (start.isSame(this.dayEnd, 'minute')) {
                return this.canWork15Hours ? BREAK_9_HOURS : BREAK_11_HOURS;
            }
            if (start.isSame(this.shortBreakStart(moment()), 'minute')) {
                return BREAK_045_HOURS;
            }
        }
        throw Error('nextBreakStart may never be empty, what have you done?');
    }
}

export class TachoStatisticsStore extends Store<TachoStatistics> {
    Model = TachoStatistics;
    static backendResourceName = 'tacho_statistics';
}
