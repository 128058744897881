import { getCurrentUser } from 'helpers/currentUser';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Table, Message } from 'semantic-ui-react';
import { Model } from 'store/Base';
import { HistoryStore } from 'store/History';
import HistoryItemLog from 'component/History/HistoryItemLog'
import { t } from '../../i18n';
import styled from 'styled-components';
import _ from 'lodash';

const NoBorderHeaderCell = styled(Table.HeaderCell)`
    &&& {
        border: 0;
    }
`

@observer
export default class HistoryItem extends Component {
    static propTypes = {
        object: PropTypes.instanceOf(Model).isRequired,
        afterSave: PropTypes.func.isRequired,
        afterFetch: PropTypes.func
    };

    @observable currentUser = getCurrentUser();
    @observable history = new HistoryStore({ relations: ['user', 'changes'] });

    componentDidMount() {
        const { object, afterFetch } = this.props;
        if (object.id !== null) {
            this.history.url = object.url + 'history/';
            this.history.fetch().then(() => {
                if (afterFetch) {
                    afterFetch(this.history);
                }
                this.forceUpdate();
                        });
        }
    }

    groupHistory = _.memoize((history) => {
        return history.groupedByDate();
    })


    render() {
        if (this.history.models.length === 0) {
            return (
                <Message warning>
                    <p>{t('order.edit.segments.emptyBox')}</p>
                </Message>
            )
        }

        return (
            <Table style={{ borderCollapse: 'collapse' }} data-test-history-feed>
                <Table.Header>
                    <Table.Row>
                        <NoBorderHeaderCell collapsing/>
                        <NoBorderHeaderCell>{t('history.field.user.label')}</NoBorderHeaderCell>
                        <NoBorderHeaderCell>{t('history.field.time.label')}</NoBorderHeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {this.groupHistory(this.history).map(historyItem =>
                        <HistoryItemLog historyItem={historyItem} />
                    )}
                </Table.Body>
            </Table>
        )
    }
}
