import { Model, Store, Casts } from 'store/Base';
import { observable } from 'mobx';
import { Location } from './Location';
import { Order } from './Order';
import { Invoice } from './Invoice';
import { formatMoneyEuro, formatMoneyPound } from 'helpers';
import { CURRENCY_POUND } from './enums/InvoiceCurrency';
import { User } from './User';

export const TYPE_NORMAL = 'normal';
export const TYPE_CUSTOM = 'custom';
export const TYPES = [TYPE_NORMAL, TYPE_CUSTOM];


export class InvoiceLine extends Model {
    static backendResourceName = 'invoice_line';

    @observable id = null;

    @observable type = TYPE_NORMAL;
    @observable description = '';

    @observable km = '';
    @observable vat = '';
    @observable total = '';

    @observable createdAt = null;
    @observable updatedAt = null;

    relations() {
        return {
            order: Order,
            invoice: Invoice,
            fromLocation: Location,
            toLocation: Location,
            createdBy: User,
        };
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }

    getFormattedTotalPrice(currency) {
        if (currency === CURRENCY_POUND) {
            return formatMoneyPound(this.total);
        }

        return formatMoneyEuro(this.total);
    }
}

export class InvoiceLineStore extends Store {
    Model = InvoiceLine;
    static backendResourceName = 'invoice_line';
}
