import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Content } from 're-cy-cle';
import { Button, Menu, Table } from 'semantic-ui-react';
import styled from 'styled-components';
import { PageLoader } from 'component/Compact/Loader';
import { t } from 'i18n';
import { TargetTextInput, TargetSelect, TargetCheckbox, ViewEdit, RightDivider, SaveButton , HeaderRight, showSaveNotification, ViewEditProps } from '@code-yellow/spider';
import { ACTION_TEMPLATE_TYPES } from '../../store/enums/DriverActionType';
import { DriverActionTemplate, DriverActionTemplateStore } from '../../store/DriverActionTemplate';

const MenuCustom = styled(Menu)`
    .item {
        font-weight: 800 !important;
        margin-bottom: 10px !important;

        &.active, &:hover {
            background-color: var(--white) !important;
        }
    }
`;

const ButtonCustom = styled(Button)`
    margin: 2px 0px 0px 0px!important;
`;

const TargetCheckboxCustom = styled.div`
    margin: 10px 0px 0px 18px!important;
`;

const SidebarContentContainer = styled.div`
    position: absolute;
    overflow-y: auto;
    height: 100%;
    padding: 20px;
    width: 100%;
`

const StyledAside = styled.aside`
    background-color: var(--gray-100);
    position: relative;
    overflow: hidden;

    max-width: 300px!important;
    width: 300px!important;
`;

class CustomViewEdit extends ViewEdit<ViewEditProps> {
    _renderMainContent() {
        const { renderMainContent } = this.props;

        if (renderMainContent != null) {
            return renderMainContent()
        }
        return '[NOT IMPLEMENTED] No Main Content defined'
    }

    _renderSidebarContent() {
        const { renderSidebarContent } = this.props;

        if (renderSidebarContent != null) {
            return (
                <StyledAside>
                    {renderSidebarContent()}
                </StyledAside>
            )
        }
        return null;
    }
}

export type ActivityTemplateOverviewProps = {
    match: null,
    history: null,
    viewStore: null,
    items: object,
}

@observer
export default class ActivityTemplateOverview extends Component<ActivityTemplateOverviewProps> {
    @observable activeItem = '';
    @observable templateStore: DriverActionTemplateStore = new DriverActionTemplateStore({ params: { '.name': this.activeItem } });

    componentDidMount() {
        const { items } = this.props;
        const keys = Object.keys(items);

        if (keys?.length > 0) {
            this.activeItem = keys[0];
            this._fetchStore(items[this.activeItem]);
        }
    }

    _fetchStore = (templateName) => {
        this.templateStore.params['.name'] = templateName?.toLowerCase();
        this.templateStore.wrapPendingRequestCount(this.templateStore.fetch());
    }

    _renderTitle() {
        return t('driverActivityTemplate.overview.title')
    }

    _renderTableHeader() {
        return (
            <Table.Row>
                <Table.HeaderCell width={3}>
                    {t('driverActivityTemplate.headers.type')}
                </Table.HeaderCell>
                <Table.HeaderCell width={7}>
                    {t('driverActivityTemplate.headers.label')}
                </Table.HeaderCell>
                <Table.HeaderCell width={7}>
                    {t('driverActivityTemplate.headers.details')}
                </Table.HeaderCell>
                <Table.HeaderCell width={1}>
                    {t('driverActivityTemplate.headers.optional')}
                </Table.HeaderCell>
                <Table.HeaderCell width={1}>
                </Table.HeaderCell>
            </Table.Row>
        )
    }

    addTemplate = () => {
        const { items } = this.props;

        const template = new DriverActionTemplate();
        template.name = items[this.activeItem];
        this.templateStore.add(template.toJS());
    }

    saveActions = () => {
        if (this.templateStore?.models.length > 0) {
            this.templateStore.wrapPendingRequestCount(
                this.templateStore.save().then(showSaveNotification).then(() => this.templateStore.fetch())
            );
        }
    }

    _renderTableRow(template) {
        return (
            <Table.Row verticalAlign='top'>
                <Table.Cell>
                    <TargetSelect clearable
                        target={template}
                        name="type"
                        noLabel
                        options={ACTION_TEMPLATE_TYPES.map(type => ({
                            value: type,
                            text: t(`driverActionTemplate.types.${type}`),
                        }))}
                        fluid
                    />
                </Table.Cell>
                <Table.Cell>
                    <TargetTextInput
                            noLabel
                            target={template}
                            name="label"
                            fluid
                        />
                </Table.Cell>
                <Table.Cell>
                    <TargetTextInput
                            noLabel
                            target={template}
                            name="description"
                            fluid
                        />
                </Table.Cell>
                <Table.Cell>
                    <TargetCheckboxCustom>
                        <TargetCheckbox fluid
                            target={template}
                            name="required"
                            noLabel
                        />
                    </TargetCheckboxCustom>
                </Table.Cell>
                <Table.Cell>
                    <ButtonCustom
                        primary
                        floated="right"
                        size="small"
                        icon="trash"
                        onClick={() => {
                            template.delete();
                            this.templateStore.remove(template);
                        }}
                        data-test-delete-button
                    />
                </Table.Cell>
            </Table.Row>
        );
    }

    _renderMainContent = () => {
        if (this.templateStore.isLoading) {
            return (
                <PageLoader show></PageLoader>
            );
        }

        return (
            <Content>
                <HeaderRight as="h3" style={{ textTransform:'capitalize' }} content={t('driverActivityTemplate.title', { type: this.activeItem })} />
                <Table>
                    <Table.Header>
                        {this._renderTableHeader()}
                    </Table.Header>
                    <Table.Body>
                        {this.templateStore.map(this._renderTableRow.bind(this))}
                    </Table.Body>
                </Table>
                <Button primary data-test-add-template
                    content={t('driverActivityTemplate.add')}
                    icon='add'
                    labelPosition='left'
                    onClick={() => this.addTemplate()}
                />
            </Content>
        );
    }

    onMenuClick = ( name ) => {
        const { items } = this.props;

        this.activeItem = name;
        document.location.href = `#${name}`;
        this._fetchStore(items[this.activeItem]);
    }

    _renderSidebarContent() {
        const { items } = this.props;
        const keys = Object.keys(items);

        return (
            <SidebarContentContainer>
                <MenuCustom secondary vertical color={'black'}>
                    {keys?.length > 0 && keys.map((item) => (
                        <Menu.Item
                            name={item}
                            active={this.activeItem === item}
                            onClick={() => this.onMenuClick(item)}
                        />
                    ))}
                </MenuCustom>
            </SidebarContentContainer>
        )
    }

    _renderToolbarContent() {
        return (
            <>
                <RightDivider />
                <SaveButton primary compact
                    onClick={() => this.saveActions()}
                    loading={this.templateStore.isLoading}
                    data-test-save-template
                    shortcut="shift+enter"
                />
            </>
        )
    }

    render() {
        return <>
            <CustomViewEdit
                renderTitle={() => this._renderTitle()}
                renderSidebarContent={() => this._renderSidebarContent()}
                renderMainContent={() => this._renderMainContent()}
                renderToolbarContent={() => this._renderToolbarContent()}
            />
        </>;
    }
}